import React from "react";

// Customizable Area Start
import { Box, Button, Container, Grid, Hidden, InputAdornment, Paper, TextField, Typography, styled,CircularProgress, IconButton } from "@material-ui/core";
import { DropdownLogo, LanguageLogo, backDrop, boxheaderLogo, emailLogo, headerLogo, lockLogo, sideLogo } from "../../email-account-registration/src/assets";
import PrivacyPolicy from "../../termsconditions/src/PrivacyPolicy.web";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import { createTheme } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
const ModalStyle = styled(Box)({

  "& .form-logo": {
    textAlign: "center",

  },

  "& .paper-container": {
    backgroundImage: `url(${backDrop})`,
    height: "100vh",
    overflowX: 'hidden',
  },

  "& .header": {
    backgroundColor: "#fff",
    padding: '30px 0',
  },

  "& .main": {
    height: "calc(100vh - 80px)",
  },

  "& .paper": {
    textAlign: 'center',
    padding: 2,
  },

  "& .textPara": {
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
    marginLeft: '20px',
    marginBottom: '100px',
    color: "#0F2220",
    fontFamily: "Jost",
    fontSize: "30px",


  },
  "& .box": {
    padding: 30,
    borderRadius: "10px",
    border: "2px solid #A046FB",
    alignItems: 'center',
    background: "#FFF",
    boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
  },

  "& .form-tnc": {
    textAlign: 'center',

  },
  "& .form-termsgroup": {
    fontFamily: 'Jost',
    fontWeight: 400,
    fontSize: '12px',
    color: "#2B2B2B",

  },
  "& .form-terms": {
    color: "#A046FB",
    cursor: 'pointer',
    fontFamily: 'Jost',
    fontWeight: 400,
    fontSize: '12px',
  },

  "& .languageTxt": {
    marginLeft: '4px',
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#000",
  },
  "& .englishTxt": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: '4px',
    color: "#A046FB",

  },

  "& .welcome": {
    fontFamily: "Inter",
    fontStyle: "normal",

    fontSize: "20px",
    fontWeight: 600,

    color: "#535655",

  },

  "& .loginEmail": {
    height: '49px',
    marginTop: '20px',
    color: "var(--grey-base, #939393)",
    fontFamily: "Jost",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "16px",
  },
  "& .loginPswd": {
    height: '49px',
    color: "var(--grey-base, #939393)",
    fontFamily: "Jost",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "16px",
  },

  "& .forgetPswd": {
    color: "#A046FB",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    textAlign: 'end',
    cursor: 'pointer',
  },

  "& .btn-grid": {
    textAlign: "center",

  },

  "& .loginBtn": {
    textTransform: 'capitalize',
    background: "#A046FB",
    boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
    height: '48px',
    width: '90%',
    borderRadius: "2px",
    border: "2px solid #A046FB",
    color: '#fff',
    fontFamily: 'Inter',
  }

})

const Logo = () => {
  return (
    <>
      <Grid item xs={12}>
        <Box className="form-logo">
          <img src={boxheaderLogo} alt="boxheaderLogo" />
        </Box>
      </Grid>
    </>

  )
}


const Language = () => {
  return (
    <>
      <Grid>
        <Box style={{ textAlign: 'center', marginTop: '33px' }}>
          <img src={LanguageLogo} alt="LanguageLogo" style={{ verticalAlign: 'top' }} />
          <Typography component={"span"} className="languageTxt">Language:
            <Typography component={"span"} className="englishTxt">English
              <img src={DropdownLogo} alt="dropdownlogo" style={{ verticalAlign: 'middle', marginLeft: '4px', cursor: 'pointer' }} />
            </Typography>
          </Typography>
        </Box>
      </Grid>
    </>

  )
}
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
      // Customizable Area Start
     const Terms = () => {
        const {showTerms, showPrivacy} = this.state;
        return (
          <>
            <Grid item xs={12}>
              <Box className="form-tnc">
                <Typography className="form-termsgroup">
                  By signing in, you're agreeing to Medical Trail App's
                  <Typography className="form-terms"><Typography className="form-terms" component={'span'} id="handleClickBtn" onClick={()=>this.handleTermsClick()} >Terms and Conditions</Typography> 
                  <Typography className="form-termsgroup" component={"span"}> and </Typography>
                  <Typography className="form-terms" component={'span'} id="handleClickPrivacy" onClick={()=>this.handlePrivacyClick()}>Privacy Policy</Typography></Typography>
                </Typography>
                {showPrivacy && <PrivacyPolicy navigation={undefined} id={""} handleClosePrivacy={() => this.handleClosePrivacy()}/>}
                {showTerms && <TermsConditions navigation={undefined} id={""} handleCloseTerms={() =>this.handleCloseTerms()} />}
              </Box>
            </Grid>
          </>
      
        )
      }
    const { emailError, passwordError,email,passwordErrorText } = this.state
      // Customizable Area Start

    return (
      // Required for all blocks
      // Customizable Area start
      <React.Fragment>
        <ModalStyle>
          <Paper elevation={0} className="paper-container">
            <Box style={{ position: 'sticky', left: 0, right: 0, top: 0, }}>
              <header className="header">
                <Container maxWidth="lg">
                  <img src={headerLogo} alt="headerlogo" style={{ width: '30%' }} />
                </Container>
              </header>
            </Box>
            <Container maxWidth="lg">

              <Grid container className="main" alignItems="center">
                <Hidden smDown>
                  <Grid item xs={12} md={6}>
                    <Typography className="textPara" > Scientific trials testing vaccines for effectiveness and safety </Typography>
                    <img src={sideLogo} alt="sideLogo" />
                  </Grid>
                </Hidden>

                {/* //---- Start Login Credential UI */}

                <Grid container item xs={12} md={6} justifyContent="center" >
                  <Grid item xs={10} >
                    <Box className="box" >
                      <Grid spacing={3} container  >
                       <Logo/>
                        <Grid item xs={12}>
                          <Box className="form-logo">
                            <Typography className="welcome" > Welcome to Patientist </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            placeholder="Email Address"
                            onChange={(e) => this.handleEmailChange(e.target.value)}
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={emailLogo} />
                                </InputAdornment>
                              ),
                              style: { height: 49 },
                              className: 'loginEmail'
                            }}
                            id="email"
                            error={emailError}
                            value={email}
                            helperText={!emailError ? '' : this.state.emailErrorText}

                          />

                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            type={this.state.showPasswordLogin ? 'text' : 'password'}                                
                            id="password"
                            variant="outlined"
                            placeholder="Enter New Password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={lockLogo} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                    {this.state.password.length > 0 ? (<IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => this.handleClickShowPasswordLogin()}
                                    >
                                        {this.state.showPasswordLogin ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>) : null}
                                </InputAdornment>
                            ),
                              className: 'loginPswd'
                            }}
                            onChange={(e) => { this.handlePasswordChange(e.target.value) }}
                            value={this.state.password}
                            error={passwordError}
                            helperText={passwordError?passwordErrorText:""}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography className="forgetPswd" id="forgetPswd" onClick={() => this.handleForgetPassword()}>
                              Forgot Password?
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} className="btn-grid">
                          <Button variant="outlined"
                            className="loginBtn"
                            id="loginBtn"
                            onClick={() => this.handleLoginSubmit()}
                          > {this.state.isLoader ? <CircularProgress size={30} style={{color:'#fff'}} /> : "Login"}</Button>
                        </Grid>
                       <Terms />
                      </Grid>
                    </Box>
                    <Language/>
                  </Grid>
                </Grid>

                {/* //---- End Login Credential UI */}
              </Grid>
            </Container>
          </Paper>
        </ModalStyle>
      </React.Fragment>
      // Customizable Area End
    );
  }
}
