Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';
exports.getUserAccountApiEndPoint = "profile/user_profiles";
exports.getVisitScheduleAPiEndPoint = "/bx_block_appointment_management/schedules/attendance"


exports.getMyChatsApiEndpoint = 'chat/chats/mychats';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.APPLICATION_ID = 103849;
exports.AUTH_KEY = "ak_e3cdWTbbSwABhgY";
exports.AUTH_SECRET = "as_KkWBuPXtUQKe-wY";
exports.ACCOUNT_KEY = "ack_9Bzwz6-MyxxvbM1qBQxv";
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
  // Customizable Area End