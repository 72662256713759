// App.js - WEB
import React, { Component, useEffect } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Mentionstagging from '../../blocks/mentionstagging/src/Mentionstagging';
import Customisableuserprofiles2 from '../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2.web';
import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import Languageswitch from '../../blocks/languageswitch/src/Languageswitch';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import Elasticsearch2 from '../../blocks/elasticsearch2/src/Elasticsearch2';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import Groups from '../../blocks/groups/src/Groups';
import HelpCentre from '../../blocks/helpcentre/src/HelpCentre';
import HelpCentreQA from '../../blocks/helpcentre/src/HelpCentreQA';
import HelpCentreSub from '../../blocks/helpcentre/src/HelpCentreSub';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import Customform3 from '../../blocks/customform3/src/Customform3';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Contentmanagement3 from '../../blocks/contentmanagement3/src/Contentmanagement3';
import Appointments from '../../blocks/appointmentmanagement/src/Appointments';
import AddAppointment from '../../blocks/appointmentmanagement/src/AddAppointment';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import Notifications from '../../blocks/notifications/src/Notifications';
import Cfzoomintegration44 from '../../blocks/cfzoomintegration44/src/Cfzoomintegration44';
import Customisableusersubscriptions from '../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions';
import SubscriptionDetails from '../../blocks/customisableusersubscriptions/src/SubscriptionDetails';
import Blogpostsmanagement from '../../blocks/blogpostsmanagement/src/Blogpostsmanagement';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import AccountGroups from '../../blocks/accountgroups/src/AccountGroups';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import Settings2 from '../../blocks/settings2/src/Settings2';
import Rolesandpermissions from '../../blocks/rolesandpermissions/src/Rolesandpermissions';
import Linkshare from '../../blocks/linkshare/src/Linkshare';
import CreateComment from '../../blocks/comments/src/CreateComment';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import Patient from '../../blocks/dashboard/src/Patient.web';
import TaskList from '../../blocks/dashboard/src/TaskList.web';
import Chat from '../../blocks/chat/src/Chat';
import ChatView from '../../blocks/chat/src/ChatView';
import Broadcastmessage from '../../blocks/broadcastmessage/src/Broadcastmessage';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions.web';
import TermsConditionsDetail from '../../blocks/termsconditions/src/TermsConditionsDetail';
import TermsConditionsUsers from '../../blocks/termsconditions/src/TermsConditionsUsers';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import Loyaltysystem from '../../blocks/loyaltysystem/src/Loyaltysystem';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories.web';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications.web';
import GroupsWeb from '../../blocks/groups/src/Groups.web';
import { QuickBlox } from 'quickblox/quickblox';


const routeMap = {
  Mentionstagging: {
    component: Mentionstagging,
    path: '/Mentionstagging'
  },
  Customisableuserprofiles2: {
    component: Customisableuserprofiles2,
    path: '/Customisableuserprofiles2'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  Languageswitch: {
    component: Languageswitch,
    path: '/Languageswitch'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  Elasticsearch2: {
    component: Elasticsearch2,
    path: '/Elasticsearch2'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  Groups: {
    component: Groups,
    path: '/Groups'
  },
  HelpCentre: {
    component: HelpCentre,
    path: '/HelpCentre'
  },
  HelpCentreQA: {
    component: HelpCentreQA,
    path: '/HelpCentreQA'
  },
  HelpCentreSub: {
    component: HelpCentreSub,
    path: '/HelpCentreSub'
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2'
  },
  Customform3: {
    component: Customform3,
    path: '/Customform3'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Contentmanagement3: {
    component: Contentmanagement3,
    path: '/Contentmanagement3'
  },
  Appointments: {
    component: Appointments,
    path: '/Appointments'
  },
  AddAppointment: {
    component: AddAppointment,
    path: '/AddAppointment'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Cfzoomintegration44: {
    component: Cfzoomintegration44,
    path: '/Cfzoomintegration44'
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: '/Customisableusersubscriptions'
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: '/SubscriptionDetails'
  },
  Blogpostsmanagement: {
    component: Blogpostsmanagement,
    path: '/Blogpostsmanagement'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  AccountGroups: {
    component: AccountGroups,
    path: '/AccountGroups'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: '/Rolesandpermissions'
  },
  Linkshare: {
    component: Linkshare,
    path: '/Linkshare'
  },
  Comment: {
    component: Comment,
    path: '/Comment'
  },
  CreateComment: {
    component: CreateComment,
    path: '/CreateComment'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  Patient: {
    component: Patient,
    path: '/Patient'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  CommunityForum: {
    component: GroupsWeb,
    path: "/CommunityForum"
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  ChatView: {
    component: ChatView,
    path: '/ChatView'
  },
  // GroupVideoCall:{
  // component:GroupVideoCall,
  // path:"/GroupVideoCall"},
  Broadcastmessage: {
    component: Broadcastmessage,
    path: '/Broadcastmessage'
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions'
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail'
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/',
    exact: true
  },
  Loyaltysystem: {
    component: Loyaltysystem,
    path: '/Loyaltysystem'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});


const APPLICATION_ID = 103325;
const AUTH_KEY = "ak_DgCfDaNhYzuF3NU";
const AUTH_SECRET = "as_bAPcA9mH9vLgZvJ";
const ACCOUNT_KEY = "ack_djxvQgxHLG1B3PA4iQyP";
const CONFIG = {
  // other settings
  webrtc: {
    disconnectTimeInterval: 86400,
  }
  // other settings
};


class App extends Component {
  QB = new QuickBlox()
  componentDidMount() {
    console.log('QuickBlox Initialize');
    this.QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY,CONFIG);
  }
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
