import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import {setStorageData,getStorageData} from "../../../framework/src/Utilities"
import { QuickBlox } from "quickblox/quickblox";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  emailError: any;
  passwordError: any;
  passwordErrorText: string;
  token: string;
  emailErrorText: string;
  isLoader: boolean;
  showTerms:boolean,
  showPrivacy:boolean,
  showPasswordLogin:boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailPasswordLoginAPIid: any;
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  CustomCheckBoxProps: any;
  txtInputPasswordProps:any;
  txtInputEmailProps:any;
  btnPasswordShowHideProps:any;
  btnPasswordShowHideImageProps:any;
  btnRememberMeProps:any;
  btnSocialLoginProps:any;
  QB = new QuickBlox()
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      emailError: null,
      passwordError:false,
      passwordErrorText: '',
      token:'',
      emailErrorText: '',
      isLoader: false,
      showTerms:false,
      showPrivacy:false,
      showPasswordLogin:false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const email = await getStorageData("email")
    this.setState({email:email})
    // Customizable Area End
  }

  // Customizable Area Start



  handleEmailChange = async (value: string) => {
    const regex = configJSON.emailRegex
    
    if (value === '' || value.match(regex)) {
      this.setState({ emailError: false, emailErrorText: '' });
    } else {
      this.setState({ emailError: true, emailErrorText: 'Please enter a valid email' });
    }
    this.setState({ email: value });
  }

  handlePasswordChange = (value: any) => {
    const strongPasswordRegex = configJSON.passwordRegex;
    let passwordError = false;
    let passwordErrorText = '';
    if (value !== "" && !value.match(strongPasswordRegex)) {
      passwordError = true;
      passwordErrorText = configJSON.errorPasswordNotValid;
    }
    this.setState({
      passwordError,
      passwordErrorText,
      password: value,
    });
  }


  handleLoginSubmit = () => {
    if (this.state.email === "" && this.state.password === "") {
      this.setState({ emailError: true, passwordError: true, passwordErrorText: configJSON.errorPasswordNotValid,emailErrorText:"Please enter a valid email" });
    } else if (this.state.email === "" || !configJSON.emailRegex.test(this.state.email)) {
      this.setState({ emailError: true, emailErrorText: "Please enter a valid email"});
    } else if (this.state.password === "" || !configJSON.passwordRegex.test(this.state.password)) {
      this.setState({ passwordError:true, passwordErrorText: configJSON.errorPasswordNotValid });
    } else {
      this.setState({ emailError: false, passwordError: false, isLoader:true });
      this.postEmailPasswordLogin();
      
      
    }
  }

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleForgetPassword = () => {
    this.props.navigation.navigate("ForgotPassword")
  }


  postEmailPasswordLogin = () => {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token
      };
  
      let formDataCampaignEmailPassword = {
          "data": {
          "type": "email_account",
            "attributes":
            {
                "email": this.state.email,
                "password":  this.state.password,
                "role_name": "Site Coordinator"
            }
          }
        }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailPasswordLoginAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(formDataCampaignEmailPassword)
      );
      
      this.emailPasswordLoginAPIid = requestMessage.messageId;
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  
    handleEmailPasswordLogin = (responseJson: any) => {      
      if (responseJson && responseJson.errors) {
        const error = responseJson.errors[0];
        if (error.failed_login === "Account not found, or not activated") {
          this.setState({ emailError: true, emailErrorText: "Email not found, please enter correct email!",isLoader:false });
        } 
        else if (error.failed_login === "Login Failed") {
          this.setState({ passwordError: true, passwordErrorText: "Your password is incorrect!",isLoader:false });
        }
      }
      if(responseJson && responseJson.data){
        let token = responseJson.data.token
        let userUniqnueId = responseJson.data.id
        setStorageData("token",token)
        setStorageData("currentPassword", this.state.password)
        localStorage.setItem("userUniqnueId",userUniqnueId)
        localStorage.setItem("currentUserId", responseJson.data?.id)
        this.setState({token},()=>{
          this.props.navigation.navigate("Categoriessubcategories")
        })
        this.setState({isLoader:false})
      }
    }
    

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    
    const handleUserCredentials = (message: Message) => {
      if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
        const userName = message.getData(getName(MessageEnum.LoginUserName));
        const password = message.getData(getName(MessageEnum.LoginPassword));
        const countryCode = message.getData(getName(MessageEnum.LoginCountryCode));
  
        if (!countryCode && userName && password) {
          this.setState({
            email: userName,
            password: password,
            checkedRememberMe: true,
          });
  
          this.setTxtInputValues(userName, password);
          this.CustomCheckBoxProps.isChecked = true;
        }
      }
    };
  
    const handleRestApiResponse = (message: Message) => {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
  
        if (apiRequestCallId != null) {
          if (
            apiRequestCallId === this.validationApiCallId &&
            responseJson !== undefined
          ) {
            this.handleValidationApiResponse(responseJson);
          }
  
          if (apiRequestCallId === this.apiEmailLoginCallId) {
            this.handleApiEmailLoginResponse(responseJson, errorReponse);
          }
  
          if (apiRequestCallId === this.emailPasswordLoginAPIid && responseJson) {
            this.handleEmailPasswordLogin(responseJson);
          }
        }
      }
    };
  
    handleUserCredentials(message);
    handleRestApiResponse(message);

    // Customizable Area End
  }
  // Customizable Area Start
  handleTermsClick = () => {
    this.setState({showTerms:true})
   };
   handlePrivacyClick = () => {
    this.setState({showPrivacy:true})
   }
   handleCloseTerms = () => {
    this.setState({showTerms:false})
   }
   handleClosePrivacy = () => {
    this.setState({showPrivacy:false})
   }
  setTxtInputValues(userName: any, password: any) {
    throw new Error("Method not implemented.");
  }
  handleValidationApiResponse(responseJson: any) {
    throw new Error("Method not implemented.");
  }
  handleApiEmailLoginResponse(responseJson: any, errorReponse: any) {
    throw new Error("Method not implemented.");
  }
  handleClickShowPasswordLogin = () => {
    this.setState((prevState) => ({
      showPasswordLogin: !prevState.showPasswordLogin
    }))
   }
  // Customizable Area End

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
