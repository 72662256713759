import React from "react";

// Customizable Area Start
import { Box, Button, Container, Dialog, DialogActions, DialogTitle, Grid, Hidden, InputAdornment, Paper, TextField, Typography, DialogContent, styled, CircularProgress, Snackbar, IconButton } from "@material-ui/core";
import { DropdownLogo, LanguageLogo, backDrop, boxheaderLogo, buttonCancel, emailLogo, headerLogo, lockLogo, rightLogo, sideLogo } from "./assets";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// Customizable Area End

import ForgotPasswordController, {
    Props
} from "./ForgotPasswordController";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import PrivacyPolicy from "../../termsconditions/src/PrivacyPolicy.web";

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    Logo = () => {
        return (
            <Grid item xs={12}>
                <Box className="form-logo">
                    <img src={boxheaderLogo} alt="boxheader-logo" />
                </Box>
            </Grid>
        )
    }

    Terms = () => {
        const {showPrivacy, showTerms } = this.state
        return (
            <Grid item xs={12}>
                <Box className="form-tnc">
                    <Typography className="form-termsgroup">
                        By signing in, you're agreeing to Medical Trail App's
                        <Typography className="form-terms"><Typography className="form-terms" component={'span'} id="handleTermsBtn" onClick={()=>this.handleTermsClick()} >Terms and Conditions</Typography> 
                            <Typography className="form-termsgroup" component={"span"}> and </Typography>
                            <Typography className="form-terms" component={'span'} id="handlePrivacyBtn"  onClick={()=>this.handlePrivacyClick()}>Privacy Policy</Typography></Typography>
                    </Typography>
                    {showTerms && <TermsConditions navigation={undefined} id={""} handleCloseTerms={() =>this.handleCloseTerms()} />}
                    {showPrivacy && <PrivacyPolicy navigation={undefined} id={""} handleClosePrivacy={() => this.handleClosePrivacy()}/>}
                </Box>
            </Grid>
        )
    }

    Language = () => {
        return (

            <Grid>
                <Box style={{marginTop: '33px', textAlign: 'center',  }}>
                    <img src={LanguageLogo} style={{ verticalAlign: 'top' }} alt="Language-Logo"  />
                    <Typography  className="languageTxt" component={"span"}>Language:
                        <Typography  className="englishTxt" component={"span"}>English
                            <img alt="dropdown-logo" src={DropdownLogo}  style={{ verticalAlign: 'middle', marginLeft: '4px', cursor: 'pointer' }} />
                        </Typography>
                    </Typography>
                </Box>
            </Grid>
        )
    }
    Dialog = () => {
        const { password, confirmPassword, confirmPasswordError, passwordError } = this.state;
        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.handleClose()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogStyle>
                    <DialogActions>
                        <img src={buttonCancel} alt="button-cancel-logo"
                            data-test-id="cancel-password"
                            onClick={() => this.handleClose()} style={{ cursor: 'pointer' }} />
                    </DialogActions>
                    <DialogTitle >
                        <Typography className="dialog-title">Re-set Your Password</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography className="dialog-para">
                            Please enter your new password for reset the
                            password
                        </Typography>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="password"
                                type={this.state.showPasswordForget ? 'text' : 'password'}                                
                                value={password}
                                onChange={(e) => { this.handlePasswordChange(e.target.value) }}
                                placeholder="Enter New Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {password.length > 0 ? (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.handleClickShowPasswordForget()}
                                                    >
                                                        {this.state.showPasswordForget ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            ) : null}
                                        </InputAdornment>
                                    ),
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),
                                    className: "newPswd"
                                }}
                                inputProps={{ 'data-test-id': "pass-word" }}
                                error={passwordError !== ''}
                            />
                            <Typography className="passwordError">{this.state.passwordError}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                placeholder="Confirm New Password"
                                value={confirmPassword}
                                variant="outlined"
                                id="cPassword"
                                onChange={(e) => this.handleConfirmPasswordChange(e.target.value)}
                                type={this.state.showConfirmPasswordForget ? 'text' : 'password'}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {confirmPassword.length > 0 ? (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.handleClickShowConfirmPasswordForget()}
                                                    >
                                                        {this.state.showConfirmPasswordForget ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            ) : null}
                                        </InputAdornment>
                                    ),
                                    className: "ConfirmNewPswd",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),

                                }}
                                inputProps={{ 'data-test-id': "confirm-password" }}
                                helperText={confirmPasswordError}
                                error={confirmPasswordError !== ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined"
                                data-test-id="change-pswd"
                                className="change-password-btn"
                                disabled={this.state.isLoading}
                                fullWidth onClick={() => this.submitPassWord()}>
                                {this.state.isLoading ? <CircularProgress size={30} style={{ color: "#A046FB" }} /> : "Create Password"}
                            </Button>
                        </Grid>
                    </DialogContent>

                </DialogStyle>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start        
        const { emailScreen, otpScreen, passwordSuccess, emailError, email, otp, timerActive, otpTimerReach } = this.state;
        return (
            <>
            <Snackbar
              open={this.state.openSnackbarInvalidToken}
              onClose={this.handleSnackbarTokenClose}
              message="Invalid Session!"
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              autoHideDuration={3000}
              action={
                  <Button size="small" color="secondary" onClick={this.handleSnackbarTokenClose}>
                      <img src={buttonCancel} alt="cancelBtn" />
                  </Button>
              }
            />
                <ModalStyle>
                    <Paper className="paper-container" elevation={0} >
                        <Box style={{ position: 'sticky', right: 0, top: 0, left: 0, }}>
                            <header className="header">
                                <Container maxWidth="lg">
                                    <img src={headerLogo} style={{ width: '30%' }} alt="header-logo" />
                                </Container>
                            </header>
                        </Box>
                        <Container maxWidth="lg">
                            <Grid className="main" container alignItems="center">
                                <Hidden smDown>
                                    <Grid item md={6} xs={12}>
                                        <Typography className="textPara">Scientific trials testing vaccines for effectiveness and safety</Typography>
                                        <img alt="side-logo" src={sideLogo} />
                                    </Grid>
                                </Hidden>
                                {/* //---- Start Email Registration UI */}
                                {emailScreen && <Grid container item xs={12} md={6} justifyContent="center">
                                    <Grid item xs={10} >
                                        <Box className="box">
                                            <Grid spacing={3} container>
                                                {this.Logo()}
                                                <Grid xs={12} item>
                                                    <Box className="form-logo">
                                                        <Typography className="pleaseText">Please enter your email address for OTP <Typography component={'div'} className="pleaseText" >verification</Typography> </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid xs={12} item >
                                                    <TextField
                                                        onChange={(e) => this.handleEmailChange(e.target.value)}
                                                        id="email"
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="Email Address"
                                                        InputProps={{
                                                            style: { height: 49 },
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={emailError === false ? rightLogo : ""} />
                                                                </InputAdornment>
                                                            ),
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={emailLogo} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        error={emailError}
                                                        className="emailPlaceholder"
                                                        value={email}
                                                        inputProps={{ "data-test-id": "email-address" }}
                                                        helperText={!emailError ? '' : this.state.emailErrorText}
                                                    />

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button variant="outlined"
                                                        data-test-id="verify"
                                                        className="continueBtn"
                                                        disabled={this.state.isLoading}
                                                        fullWidth onClick={() => { this.handleEmailSubmit() }}>
                                                        {this.state.isLoading ? <CircularProgress size={30} style={{ color: "#A046FB" }} /> : "Verify"}
                                                    </Button>
                                                </Grid>
                                                {this.Terms()}
                                            </Grid>
                                        </Box>
                                        {this.Language()}
                                    </Grid>
                                </Grid>}
                                {otpScreen && <Grid container item md={6} xs={12} justifyContent="center" >
                                    <Grid item xs={10} >
                                        <Box className="box">
                                            <Grid className="form-content" container spacing={3} >
                                                {this.Logo()}
                                                <Grid item xs={12}>
                                                    <Box className="form-logo">
                                                        <Typography className="enter-otp">
                                                            Please enter the 6 Digit OTP and verify your <br /> email address{" "}
                                                            <Typography className="edit-email" component={"span"} onClick={this.handleEditEmailAddress}>
                                                                Edit Email Address
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder="Email Address"
                                                        fullWidth
                                                        disabled
                                                        value={email}
                                                        className="disabledemail"
                                                        InputProps={{
                                                            style: { height: 49 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={emailLogo} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <Box className="timer"> {this.displayTime()}</Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        InputProps={{
                                                            className: "otp",
                                                        }}
                                                        variant="outlined"
                                                        onChange={(e) => this.handleOtpChange(e)}
                                                        value={otp}
                                                        id="otpInput"
                                                        fullWidth
                                                        placeholder="000000"
                                                        inputProps={{ "data-test-id": 'otp-input' }}
                                                        error={this.state.validOtp}
                                                        onKeyDown={(e) => this.handleOtpChange(e)}
                                                        disabled={otpTimerReach}
                                                        helperText={this.state.validOtp ? this.state.validateOtpMsg : ''}
                                                    />
                                                </Grid>
                                                <Typography>{this.state.otpError}</Typography>
                                                <Grid item xs={12}>
                                                    <Box className="form-otp">
                                                        <Typography className="didnot-got">
                                                            Didn't got the OTP?
                                                            <Button
                                                              variant="text"
                                                              disabled={otpTimerReach || timerActive}
                                                              className={`resend-otp ${timerActive || otpTimerReach? 'active' : 'inactive'}`}
                                                              onClick={this.handleResendOtp}
                                                            >
                                                                Resend OTP
                                                            </Button>
                                                            <Snackbar
                                                                open={this.state.openSnackbar}
                                                                onClose={this.handleSnackbarClose}
                                                                message="OTP Resent!"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                                autoHideDuration={3000}
                                                                action={
                                                                    <Button size="small" color="secondary" onClick={this.handleSnackbarClose}>
                                                                        <img src={buttonCancel} alt="cancelBtn" />
                                                                    </Button>
                                                                }
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        disabled={this.state.isLoading || this.state.otpTimerReach}
                                                        className="submitBtn" fullWidth
                                                        data-test-id="submit-button"
                                                        variant="outlined"
                                                        onClick={() => this.handleClickOpen()}>
                                                        {this.state.isLoading ? <CircularProgress style={{ color: "#A046FB" }} size={30} /> : "Submit"}
                                                    </Button>
                                                    {this.Dialog()}
                                                </Grid>
                                                {this.Terms()}
                                            </Grid>
                                        </Box>
                                        {this.Language()}
                                    </Grid>
                                </Grid>}

                                {passwordSuccess && (<Grid container item md={6} xs={12} justifyContent="center" >
                                    <Grid item xs={10} >
                                        <Box className="box">
                                            <Grid spacing={3} container >
                                                {this.Logo()}
                                                <Grid item xs={12}>
                                                    <Box className="form-logo">
                                                        <Typography className="form-welcome">Welcome to Patientist</Typography>
                                                        <Typography className="form-created">Password Created Successfully </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button className="loginPswrdBtn" variant="outlined" onClick={() => this.loginNavigationButton()} fullWidth>Login with password</Button>
                                                </Grid>
                                                {this.Terms()}
                                            </Grid>
                                        </Box>
                                        {this.Language()}
                                    </Grid>
                                </Grid>)}
                            </Grid>
                        </Container>
                    </Paper>
                </ModalStyle>
            </>
        );
        // Customizable Area End

    }
}




// Customizable Area Start

const ModalStyle = styled(Box)({
    "& .header": {
        backgroundColor: "#fff",
        padding: '30px 0',
    },
    "& .paper-container": {
        backgroundImage: `url(${backDrop})`,
        height: "100vh",
        overflowX: 'hidden',
    },
    "& .main": {
        height: "calc(100vh - 80px)",
    },
    "& .pleaseText": {
        fontWeight: 400,
        textAlign: "center",
        fontFamily: "Inter",
        color: "#767E85",
        fontSize: "14px",
        fontStyle: "normal",
        fontFeatureSettings: "'clig' off, 'liga' off",
    },
    "& .paper": {
        textAlign: 'center',
        padding: 2,
    },
    "& .box": {
        padding: 30,
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        borderRadius: "10px",
        alignItems: 'center',
        background: "#FFF",
        border: "2px solid #A046FB",
    },
    "& .textPara": {
        fontStyle: "normal",
        marginBottom: '100px',
        textTransform: "capitalize",
        fontFamily: "Jost",
        marginLeft: '20px',
        fontSize: "30px",
        fontWeight: 700,
        color: "#0F2220",
    },
    "& .form-logo": {
        color: "#767E85",
        textAlign: "center",
    },
    "& .emailPlaceholder": {
        marginTop: "56px",
        color: "#0F2220",
        fontSize: "30px",
        fontFamily: "Jost",
    },
    "& .form-tnc": {
        textAlign: 'center',
    },
    "& .continueBtn": {
        height: 50,
        marginTop: 97,
        borderRadius: "4px",
        textTransform: 'capitalize',
        background: "#FFF",
        border: "2px solid #A046FB",
        fontFamily: "Jost",
        fontWeight: 'bolder',
        fontSize: "16px",
        color: "#0F2220",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
    },
    "& .form-terms": {
        fontWeight: 400,
        cursor: 'pointer',
        fontFamily: 'Jost',
        fontSize: '12px',
        color: "#A046FB",
    },
    "& .form-termsgroup": {
        fontFamily: 'Jost',
        fontWeight: 400,
        fontSize: '12px',
        color: "#2B2B2B",
    },
    "& .englishTxt": {
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#A046FB",
        marginLeft: '4px',
        fontFamily: "Inter",
    },
    "& .languageTxt": {
        marginLeft: '4px',
        fontFamily: "Inter",
        fontSize: "14px",
        color: "#000",
        fontWeight: "bold",
        fontStyle: "normal",
    },
    "& .enter-otp": {
        fontStyle: "normal",
        textAlign: "center",
        fontSize: "14px",
        color: "#767E85",
        fontWeight: 400,
        cursor: 'pointer',
        fontFamily: "Inter",
    },
    "& .form-content": {
        textAlign: 'center'
    },
    "& .edit-email": {
        fontWeight: 700,
        color: "#A046FB",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
    },
    "& .otp": {
        marginTop:'-20px',
        height: "49px",
    },
    "& .disabledemail": {
        fontFamily: "Jost",
        fontSize: "30px",
        color: "#0F2220",
    },
    "& .form-otp": {
        marginTop:'-20px',
        textAlign: 'end',
    },
    "& .resend-otp.active": {
        color: "#767E85 !important",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        marginLeft: '6px',
        cursor: 'pointer'
    },
    "& .resend-otp.inactive": {
        fontWeight: 600,
        marginLeft: '6px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        color: "#A046FB !important",
    },
    "& .resend-otp": {
        fontWeight: 600,
        marginLeft: '6px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        color: "#767E85 !important",
        cursor: 'pointer'
    },
    "& .didnot-got": {
        fontFamily: "Inter",
        fontSize: "14px",
        color: "#767E85",
        fontWeight: 400,
        fontStyle: "normal",
    },
    "& .submitBtn": {
        borderRadius: "4px",
        height: 50,
        textTransform: 'capitalize',
        fontWeight: 'bolder',
        background: "#FFF",
        fontFamily: "Jost",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        fontSize: "16px",
        color: "#0F2220",
        border: "2px solid #A046FB",
    },
    "& .timer": {
        fontSize: "14px",
        fontFamily: "Inter",
        textAlign: 'end',
        fontStyle: "normal",
        fontWeight: 400,
        color: "#7D7D7D",
        marginTop:'10px'
    },
    "& .form-created": {
        marginTop: '16px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        fontStyle: "normal",
        color: "#767E85",
    },
    "& .form-welcome": {
        fontSize: "20px",
        fontWeight: "bolder",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        color: "#535655",
        fontStyle: "normal",
    },
    "& .loginPswrdBtn": {
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        borderRadius: "2px",
        fontWeight: 'bold',
        border: "2px solid #A046FB",
        background: "#A046FB",
        fontFamily: 'Jost',
        marginTop: '140px',
        color: '#fff',
        textTransform: 'capitalize',
        height: '48px',
    },
    "& .loginEmail": {
        fontWeight: 400,
        marginTop: '20px',
        fontSize: "16px",
        color: "var(--grey-base, #939393)",
        fontStyle: "normal",
        fontFamily: "Jost",
    },
    "& .welcome": {
        fontStyle: "normal",
        fontWeight: 600,
        fontFamily: "Inter",
        fontSize: "20px",
        color: "#535655",
    },
    "& .loginPswd": {
        fontWeight: 400,
        fontStyle: "normal",
        fontFamily: "Jost",
        fontSize: "16px",
        color: "var(--grey-base, #939393)",
    },
    "& .btn-grid": {
        textAlign: "center"
    },
    "& .forgetPswd": {
        cursor: 'pointer',
        fontWeight: 500,
        color: "#A046FB",
        fontFamily: "Inter",
        fontStyle: "normal",
        textAlign: 'end',
        fontSize: "12px",
    },
    "& .loginBtn": {
        border: "2px solid #A046FB",
        width: '90%',
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        color: '#fff',
        fontFamily: 'Inter',
        borderRadius: "2px",
        background: "#A046FB",
        height: '48px',
        textTransform: 'capitalize',
    }
})
const DialogStyle = styled(Box)({
    "& .passwordError" : {
        maxWidth:"300px",
        fontSize:"12px",
        color:"#F44336",
        fontFamily:"Inter",
        marginTop:"5px",
        marginLeft:"14px"
    },
    "& .dialog-title": {
        fontSize: "28px",
        color: "#000",
        fontWeight: "bolder",
        fontFamily: "Jost",
    },
    "& .dialog-para": {
        fontStyle: "normal",
        width: '90%',
        fontWeight: 400,
        color: "#5E5E5E",
        fontFamily: "Jost",
        fontSize: "16px",
    },
    "& .change-password-btn": {
        borderRadius: "4px",
        fontFamily: 'Jost',
        textTransform: 'capitalize',
        marginTop: '32px',
        fontSize: '14px',
        fontWeight: 'bolder',
        marginBottom: '40px',
        height: '50px',
        border: "2px solid #A046FB",
        background: "#FFF",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        color: '#000',
    },
    "& .ConfirmNewPswd": {
        fontFamily: 'Jost',
        scrollSnapMarginTop: '20px',
        height: '49px',
        marginTop: '20px',
    },
    "& .newPswd": {
        marginTop: '24px',
        height: '49px',
        fontFamily: 'Jost',
    },
})
// Customizable Area End
