import React from 'react'
import { Avatar, Box, styled } from "@material-ui/core"
import { QuickBlox } from 'quickblox/quickblox';

interface Props {
  receiverId: string,
  senderId: number,
  message: string
  senderInfo?: any,
  receiverInfo?: any,
  chatInfo: any;
  selectedFileType:string;
  selectedFileName:string;
  filePreviewUrl: string;
  QB:QuickBlox;
  filesResult: any;
}

const dateInfoChat = (chatInfo:any) => { 
  if(chatInfo?.created_at){
    const date = new Date(chatInfo?.created_at);

    const options:any = {
      hour: "numeric",
      minute: "numeric",
      hour12: true
    };
    
    const formattedTime = date.toLocaleTimeString('en-US', options);
    return formattedTime
  }
}


export const ChatItem: React.FC<Props> = (props) => {
  let {message, receiverId, receiverInfo,chatInfo,selectedFileType,selectedFileName,filePreviewUrl, QB,filesResult} = props
  const isReceived = receiverId === chatInfo?.recipient_id?1:2
  const user = isReceived===2
  

  const Message = user=== true? ReceivedMessage : SentMessage
  
  const handleAllPreviewTags = (val:any,fileUrl:any)=>{
    if(val?.type?.includes('image')){
    return <img src={fileUrl} alt="Image" style={{width:'200px', height:"150px",objectFit: 'fill',}}/>
  }else if(val?.type?.includes('pdf')){
    return <embed src={fileUrl} type="application/pdf"style={{width:'200px', height:"150px"}} />
    }else if(val?.type?.includes('video')){
      return <video controls style={{width:'200px', height:"150px"}}><source src={fileUrl} type="video/mp4" /></video>
    }
  }
  
  const filePreview = ()=>{
    if(chatInfo?.attachments?.length>0){
      return chatInfo?.attachments?.map((val:any)=>{
        const fileUrl=QB.content.privateUrl(val?.id);
        const MessageAttachment = val?.id && user? ReceivedMessageAttachment : SentMessageAttachment
        return <Box display={'flex'} flexDirection={"column"}>
          <Message>{handleAllPreviewTags(val,fileUrl)}</Message>
          <MessageAttachment>{val?.id ?message:''}</MessageAttachment>
      </Box>
      })
    }
  }
  const renderTimeReceived = () => {
    return(
      <div style={{display: 'flex', alignItems: 'center', marginBottom: 4,}}>
          <Avatar style={{ marginRight: 8 }} alt="Remy Sharp" src={"https://pbs.twimg.com/profile_images/1498641868397191170/6qW2XkuI_400x400.png"} />
          <span style={{color: 'black', marginRight: 4}}>{receiverInfo?.full_name || ""}</span>
          <span style={{color: 'lightGray'}}>{dateInfoChat(chatInfo)}</span>
        </div>
    )
  }

  const renderTimeSent = () => {
    return(
      <div style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', marginBottom: 4,}}>
        <span style={{color: 'lightGray', alignSelf: "flex-end",}}>{dateInfoChat(chatInfo)}</span>
      </div>
    )
  }

  return(
    <>
      <MessageContainer received={user ? true: false}>
        <div>
          {user ? renderTimeReceived() : renderTimeSent()}
          <MessageBox received={user}>
            {filePreview()}
              {message!==''&&!filePreview()&&<Message>
                {message}
              </Message>}
          </MessageBox>
        </div>
      </MessageContainer>
    </>
  )
}



const MessageBox = styled(Box)({
  minWidth: 0,
  wordWrap: 'break-word',
  flexDirection: 'column',
  WebkitAlignItems:((props: {received: boolean}) => props.received===true ? 'start' : 'end'),
  display: 'flex',
  alignItems: 'center',
})

const MessageContainer = styled(`div`)({
  minWidth: 0,
  wordWrap: 'break-word',
  flexDirection: ((props: {received: boolean}) => props.received===true ? 'row' : 'row-reverse'),
  display: 'flex',
  alignItems: 'center',
})

const SentMessageAttachment = styled(`p`)({
  color: '#454242',
  background: "rgba(211, 211, 211, 0.43)",
  alignSelf: "flex-end",
  maxWidth: '50vh',
  padding: 12,
  borderRadius: "20px 0px 20px 20px",
  marginBottom:"14px",
})

const ReceivedMessageAttachment = styled(`p`)({
  color: '#454242',
  background: 'rgba(211, 211, 211, 0.43)',
  alignSelf: "flex-end",
  maxWidth: '50vh',
  padding: 12,
  borderRadius: "0px 20px 20px 20px",
  marginBottom:"14px",
})

const SentMessage = styled(`p`)({
  color: 'white',
  background: "#A046FB",
  alignSelf: "flex-end",
  maxWidth: '50vh',
  padding: 12,
  borderRadius: "20px 0px 20px 20px",
})

const ReceivedMessage = styled(`p`)({
  color: 'black',
  background: 'lightGray',
  alignSelf: "flex-start",
  maxWidth: '50vh',
  padding: 12,
  borderRadius: "0px 20px 20px 20px",
})