import React from "react";
import GenericTabsController from "./GenericTabsController";
import { Box, Tabs, Tab, styled, withStyles, Theme, createStyles, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
  label: string;
}


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
}));

class GenericTabs extends GenericTabsController {
 
  render() {
    return (
      <Box style={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: grey[600] }}>
          <AntTabs
            value={this.props.selected}
            onChange={(_: any, newValue: string) => this.props.handleSelectTab(newValue)}
          
            TabIndicatorProps={{style: {backgroundColor: "#A046FB",height:"5px",
            borderRadius:'100px',
            width: this.props.selected === 'Previous' ? '81px' :  this.props.selected === 'Trail Visit Schedule' ? '185px':  '95px'}}}
          >
            {
              this.props.tabs.map((tab, index) => {
                return <StyledTab 
                  value={tab.value} 
                  label={tab.value} 
                  key={index} 
                  style={{
                    fontWeight: 800,
                    fontSize: '16px',
                    textTransform: 'none',
                  }} 
                 
                />
              })
            }
          </AntTabs>
        </Box>
      </Box>
    );
  }
}

const StyledTab = styled(Tab)({
  fontWeight: 600,
  fontSize: '14px',
  textTransform: 'none',
  textAlign:'left',
  "&.Mui-selected": {
    color:"#A046FB",
    textAlign:'left'
  }, root: {
    flexGrow: 1,
  },
  "@media (min-width: 600px)": {
    minWidth: '0px',
    padding:"6px 15px 6px 0px"
  },
  "& .MuiTab-root":{
padding:"6px 15px 6px 0px"
  }

 
});

export default GenericTabs;