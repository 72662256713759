import React from "react";
// Customizable Area Start
import {
  Box, Typography, Grid, Drawer, TextField, Avatar, Checkbox,
  FormControlLabel, IconButton, DrawerProps, Radio, RadioGroup, BoxProps, Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, Breadcrumbs,
} from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { createTheme, styled, } from "@material-ui/core/styles";
import { My_Calender, Add_schedule, info, location, down, tick, milestone, cal, time, repeat, editIcon, deleteIcon, white_loc } from "./assets";
import CustomDropdown from '../../../components/src/CustomDropDown';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment-timezone';
import Sidebar from "../../../components/src/Sidebar.web";

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CustomTimeZone from "../../../components/src/CustomTimeZone";
import CustomDropDownWithSearch from "../../../components/src/CustomDropDownWithSearch";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SideNavbar from "../../../components/src/SideNavbar.web";
import { buttonCancel } from "../../email-account-registration/src/assets";
import GenericTabs from "../../../components/src/GenericTabs.web";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
const localizer = momentLocalizer(moment)
import CustomPagination from "../../../components/src/CustomPagination.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomTextEditor from "../../../components/src/CustomTextEditor";
import AddIcon from '@material-ui/icons/Add';
// Customizable Area End

import AppointmentmanagementController, {
  Props,
  configJSON,
} from "./AppointmentmanagementController";

export default class Appointments extends AppointmentmanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderParticipentsList = (item: any, flag: boolean) => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Avatar style={webStyle.avatarstyle} src={item.profile_image ? item.profile_image : ''}>
          </Avatar>
        </Grid>
        <Grid item xs={8}>
          <Grid container >
            <Grid item xs={12}>
              <Typography style={webStyle.fullname}>{item.full_name}</Typography>
            </Grid>
            {flag ? (
              <Grid item xs={12}>
                <Typography style={webStyle.required}>Response Required</Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography style={webStyle.required}>{item.invite_response}</Typography>
              </Grid>
            )}

          </Grid>
        </Grid>
        <Grid item xs={1} >
          {flag && (
            <IconButton data-test-id="cancel-tech1" onClick={() => this.deleteItem(item)}>
              <img src={buttonCancel} alt="buttonCancel" />
            </IconButton>
          )}

        </Grid>
      </Grid>
    )
  }

  rendergridView = (contentArray: any, text: string) => {
    const { itemsPerPage, displayedContent,currentPage } = this.state;
  let contentToDisplay = displayedContent.length > 0 ? displayedContent : contentArray.slice(0, itemsPerPage * currentPage);
  let startIndex = (currentPage - 1) * itemsPerPage;
  let endIndex = startIndex + itemsPerPage;
  contentToDisplay = contentToDisplay.slice(startIndex, endIndex);

    return (
      <div>
        {contentToDisplay.map((content: any, index: any) => (

          <Box
            key={index}
            data-test-id={text}
            style={webStyle.detailsGrid}
            onClick={() => this.ShowAppointmentDetails(content)}>
            <Grid container spacing={2} className="formContainer"
              style={{ padding: "30px", marginTop: "20px", height: '255px' }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div >
                    <div style={webStyle.displayflex}>
                      <Typography style={webStyle.titleStyle}>
                        {content.attributes.title}
                      </Typography>
                      <div
                        data-test-id={text + "1"}
                        onClick={(e) => this.ShowMenu(e, content.id)}>
                        <MoreVertIcon />
                        {this.state.openMenu &&
                          (this.state.openMenuIndex === content.id) &&
                          (text === "Upcomming") &&
                          <div style={{ marginTop: '10px' }}>
                            {this.selectOptionMenu(content)}
                          </div>
                        }
                      </div>
                    </div>
                    <div
                      style={{
                        borderTop: '1px solid #cbcdd1',
                        width: '100%',
                        marginTop: '10px'
                      }} >
                    </div>
                  </div>


                </Grid>
                <Grid item xs={3}>
                  <div >
                    <Typography style={webStyle.titleStyle}>
                      Date :
                    </Typography>
                    <Typography style={webStyle.descStyel}>
                      {this.callDateChangeFormat(content.attributes.start_date)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div > <Typography style={webStyle.titleStyle}>
                    Time :
                  </Typography>
                    <Typography style={webStyle.descStyel}>
                      {content.attributes.start_time} to {content.attributes?.end_time}
                    </Typography></div>
                </Grid>
                <Grid item xs={12}>
                  <div > <Typography style={webStyle.titleStyle}>
                    Address :
                  </Typography>
                    <Typography style={webStyle.descStyel}>
                      {content.attributes.address}
                    </Typography></div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ))}
        {contentArray && (
          <Box style={webStyle.displayflex1}
          >
            <CustomPagination data={contentArray} itemsPerPage={itemsPerPage} edit={this.getUpdatedData} data-testid="custom-pagination" />

            <Box>
              {this.renderDropDown(this.state.openPages,
                this.state.pageCount, this.state.setPages,
                this.handleitemsPerPage, "page")}
            </Box>
          </Box>
        )}

      </div>

    )
  }
  renderMilestoneDrawerView() {
    return (
      <Box style={{ marginTop: '20px' }}>
        <div style={{ position: 'relative', width: '100%' }}>

          <div
            data-test-id='GetMilestone'
            style={webStyle.openmilestone} onClick={() => this.setIsOpen(!this.state.openMilestone)}>
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }} >
              <img src={milestone} style={{ width: "20px", height: "20px", marginRight: "5px" }} />


              <span style={{
                color: this.state.selectedOption ? '#454545' : 'var(--Grey-Base, #939393)',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}
              >
                {this.state.selectedOption || 'Select Milestone'}
              </span>
            </div>

            <img src={down} />
          </div>

          {this.renderMilestonArray(this.state.milestones, this.state.openMilestone, false)}

        </div>{this.state.milstoneError && (
          <Typography style={webStyle.passworderror}>{this.state.milstoneErrorText}</Typography>)}

      </Box>
    )
  }
  renderMilestoneView = () => {


    return (
      <Box style={{ marginTop: '20px' }}>
        <div style={{ position: 'relative', width: '100%' }}>

          <div
            style={{
              display: 'flex',
              padding: '10px',
              border: '1px solid #ccc',
              cursor: 'pointer',
              alignItems: 'center',
              borderRadius: '5px',
              justifyContent: 'space-between'
            }}
            data-test-id="withdrawermilestone"
            onClick={() => this.setIsOpen1(!this.state.openMilestoneWithDrawer)}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }} >
              <img src={milestone} style={{ width: "20px", height: "20px", marginRight: "5px" }} />


              <span style={this.state.selectedOption1 ? {
                color: '#454545',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              } : {
                color: 'var(--Grey-Base, #939393)',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}>
                {this.state.selectedOption1 || 'Select Milestone'}
              </span>
            </div>

            <img src={down} />
          </div>
          {this.renderMilestonArray(this.state.milestoneNew, this.state.openMilestoneWithDrawer, true)}

        </div>
      </Box>
    )
  }
  renderMilestonArray = (ArrayNew: any, flag: boolean, drawerFlag: boolean) => {
    return (
      <>
        {flag && (
          <div style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            width: '100%',
            backgroundColor: '#fff',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            zIndex: 100
          }}>{ArrayNew && ArrayNew.length > 0 && (
            <>
              {ArrayNew?.map((option: any, index: any) => (
                <div key={index} style={{
                  padding: '8px',
                  cursor: 'pointer'
                }}
                  data-test-id="clickmilestone2"
                  onClick={() => this.handleOptionClick(option, drawerFlag)}>
                  <span style={{
                    color: '#585858', fontFamily: 'Jost',
                    fontSize: '14px', fontStyle: 'normal', fontWeight: 400,
                    lineHeight: 'normal'
                  }}>{option.name}</span>

                </div>
              ))}
            </>
          )}
          </div>
        )}
      </>
    )
  }
  renderTitleDesView = () => {
    return (
      <>
        <Box style={{
          display: 'flex', paddingLeft: '12px',
          paddingRight: '8px',
          paddingTop: '4px',
          paddingBottom: '4px',
          border: '1px solid #ccc',
          cursor: 'pointer',
          alignItems: 'center',
          borderRadius: '5px',
          marginTop: '20px',
        }}>
          <img src={info} style={{ width: "20px", height: "20px", marginRight: "5px" }} />
          <TextField
            placeholder="Title"
            InputProps={{ disableUnderline: true, }}
            style={{
              color: 'var(--Grey-Base, #939393)',
              fontFamily: 'Inter',
              fontSize: '6px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal', width: '100%',

            }}
            value={this.state.title}
            data-test-id="title"
            onChange={this.changeTitle}
            error={this.state.errorTitle !== ''}
          />
        </Box>

        <Typography style={webStyle.passworderror}>{this.state.errorTitle}</Typography>
        <CustomTextEditor handleEditorChange={this.handleEditorChange}
          onSelect={this.state.editorState} />

        <Typography style={webStyle.passworderror}>{this.state.errorDesc}</Typography>
      </>
    )
  }
  renderCustomDiv = () => {
    return (
      <>
        <Box style={{ marginTop: '20px' }}>
          <div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>

              <Grid container style={{ marginTop: '10px' }} >
                <Grid item xs={3} >
                  <Typography
                    style={{
                      color: '#5A5A5A',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '26px',
                      marginTop: '10px'
                    }}>
                    Repeat Every
                  </Typography>

                </Grid>
                <Grid item xs={9}>
                  {/* Content for the second column */}

                  <Grid container spacing={2}>
                    {this.state.customValue !== "year" && (
                      <Grid item xs={6}>


                        {this.renderDropDown(this.state.customRepeatEveryFlag,
                          this.state.customRepeatEveryArray, this.state.customRepeatEveryValue,
                          this.handleCustomRepeatEvery, "1")}




                      </Grid>
                    )}
                    <Grid item xs={6}>
                      {this.renderDropDown(this.state.customFlag,
                        this.state.customArray, this.state.customValue,
                        this.handleCustomValue, "custom")}

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </div>

          </div>
        </Box>
        {this.state.customValue === "Day" && (
          <>
            {this.renderEndDate()}
          </>
        )}
        {this.state.customValue === "Week" && (
          <>
            <Box style={{ marginTop: '20px' }}>
              <Typography style={{
                color: '#5A5A5A',
                fontFamily: 'Jost',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}>
                Occurs On
              </Typography>
              <Box>
                {this.renderWeek()}
              </Box>
            </Box>
            {this.renderEndDate()}
          </>
        )}
        {this.state.customValue === "Month" && (
          <>
            {this.renderMonthlySubDiv()}
          </>
        )}
        {this.state.customValue === "Year" && (
          <>
            {this.renderYearlyDiv()}
          </>
        )}
      </>
    )
  }
  renderWeek = () => {
    return (
      <div style={{
        display: 'flex', justifyContent: 'space-between',
        marginTop: '10px'
      }}>
        {this.state.weekdays.map((day: any, index: any) => (
          <div
            key={index}
            style={this.state.selectedDays === day.short ? {
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#A046FB', cursor: 'pointer'
            } : {
              width: '40px',
              height: '40px',
              borderRadius: '50%', backgroundColor: 'white', cursor: 'pointer'
            }}
            data-test-id={day.short}
            onClick={() => this.handleDayClick(day)}
          >

            <Typography style={this.state.selectedDays === day.short ? {
              color: '#FFF',
              fontFamily: 'Jost',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'
            } : {
              color: '#939393',
              fontFamily: 'Jost',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'
            }}
            >{day.short}</Typography>

          </div>
        ))}
      </div>
    )
  }
  renderEndDate = () => {
    const today = new Date();
    return (
      <>
        <Box style={{
          display: 'flex',
          paddingRight: '4px',
          paddingTop: '4px',
          paddingBottom: '4px',
          border: '1px solid #ccc',
          cursor: 'pointer',
          alignItems: 'center',
          borderRadius: '5px',
          marginTop: '20px',
          textAlign: 'center',
        }}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            placeholder="End Date"
            format="DD/MM/YYYY"
            value={this.state.endDate}
            data-test-id="enddate"
            minDate={today}
            onChange={this.handleEndDateChange}
            autoOk
            InputAdornmentProps={{ position: "start" }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputProps={{
              disableUnderline: true,
            }}
            keyboardIcon={<img src={cal} />} />

        </Box>
        {this.state.endDateError && (
          <Typography style={webStyle.passworderror}>{this.state.endDateErrorText}</Typography>)}
      </>
    )
  }
  renderMonthlySubDiv = () => {
    return (
      <>
        <Box style={{ marginTop: '20px', }}>
          <RadioGroup
            name="status"
            data-test-id="radio1"
            value={this.state.selectedRadioMonth}
            onChange={this.handleOptionChangeRadioMonth}
            row
          >
            <Grid container >
              <Grid item xs={3}>
                <FormControlLabel
                  value="open"
                  control={<Radio color="primary" />}
                  label={
                    <Typography
                      style={{
                        color: '#5A5A5A',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '26px',
                      }}>
                      On Day
                    </Typography>}
                />
              </Grid>
              <Grid item xs={9}>

                <TextField
                  InputProps={{ disableUnderline: true }}
                  style={{
                    display: 'flex', padding: '4px',
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    alignItems: 'center',
                    borderRadius: '5px',
                    textAlign: 'center',
                  }}
                  data-test-id="onday"
                  value={this.state.on_day}
                  onChange={this.handleChangeOnDayValue}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '20px' }} >
              <Grid item xs={3} >
                <FormControlLabel
                  value="closed"
                  control={<Radio color="primary" />}
                  label={<Typography
                    style={{
                      color: '#5A5A5A',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '26px',
                    }}>
                    On the
                  </Typography>}
                />

              </Grid>
              <Grid item xs={9}>
                {/* Content for the second column */}

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {this.renderDropDown(this.state.openOnthe,
                      this.state.onthe, this.state.onthevalue,
                      this.handleOptionClickonthe, "Third")}

                  </Grid>
                  <Grid item xs={6}>
                    {this.renderDropDown(this.state.openDay,
                      this.state.dayOn, this.state.dayonvalue,
                      this.handleOptionClickDay, "Tuesday")}

                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </RadioGroup>


        </Box>
        {this.renderEndDate()}
      </>

    )
  }
  renderMonthlyDiv = () => {
    return (
      <>
        {this.repeatEvery(this.state.MonthlyValue, this.handleChangeMonthlyValue, "Month")}
        {this.renderMonthlySubDiv()}



      </>
    )
  }
  renderYearlyDiv = () => {
    return (
      <>
        <Box style={{ marginTop: '10px' }}>

          <RadioGroup
            name="status"
            data-test-id="radioYear"
            value={this.state.selectedRadioYear}
            onChange={this.handleOptionChangeRadioYear}


          >
            <div style={{ display: 'column' }}>
              <FormControlLabel
                value="open"
                control={<Radio color="primary" />}
                label={<Typography style={{
                  color: '#5A5A5A',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '26px',
                  width: '100%'
                }}>
                  On {this.state.yearlyDate}
                </Typography>}
              />


              <FormControlLabel
                value="closed"
                control={<Radio color="primary" />}
                label={<Typography style={{
                  color: '#5A5A5A',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '26px',
                  width: '100%'
                }}>
                  On the {this.state.yearlyDayFormat}
                </Typography>}
              />
            </div>

          </RadioGroup>

        </Box>
        {this.renderEndDate()}

      </>
    )
  }
  renderWeeklyDiv = () => {
    return (
      <>
        {this.repeatEvery(this.state.WeeklyValue, this.handleChangeWeeklyValue, "Week")}

        <Box style={{ marginTop: '20px' }}>
          <Typography style={{
            color: '#5A5A5A',
            fontFamily: 'Jost',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
          }}>
            Occurs On
          </Typography>
          <Box>
            {this.renderWeek()}

          </Box>
        </Box>
        {this.renderEndDate()}
        <Box style={{ marginTop: '20px' }}>
          <Typography style={{
            color: '#5A5A5A',
            fontFamily: 'Jost',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
          }}>
            Occurs every {this.state.selectedDayLong} starting {this.state.showDate}
          </Typography>
        </Box>
      </>
    )
  }
  repeatEvery = (value: any, func: any, text: any) => {
    return (
      <Box style={{
        display: 'flex', paddingLeft: '12px',
        paddingRight: '8px',
        paddingTop: '4px',
        paddingBottom: '4px',
        border: '1px solid #ccc',
        cursor: 'pointer',
        alignItems: 'center',
        borderRadius: '5px',
        marginTop: '20px',
        textAlign: 'center',
      }}>
        <img src={repeat} style={{ width: "20px", height: "20px", marginRight: "5px" }} />
        <TextField
          placeholder="Repeat Every"
          InputProps={{ disableUnderline: true }} // Disable underline
          style={{
            color: 'var(--Grey-Base, #939393)',
            fontFamily: 'Inter',
            fontSize: '6px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal', width: '80%', marginLeft: '10px'
          }}
          data-test-id={text}
          value={value}
          onChange={func}
        />
        <div style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
          <div style={{
            width: '1px',
            height: '28px',
            flexShrink: 0,
            backgroundColor: "#E6E6E6"
          }}>

          </div>
          <Typography style={{
            color: '#404040',
            fontFamily: 'Jost',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal', marginLeft: "5px",
          }}> {text}(s)</Typography>
        </div>

      </Box>
    )
  }
  renderDailyDiv = () => {
    return (
      <>
        {this.repeatEvery(this.state.dailyValue, this.handleChangeDailyValue, "Day")}

        {this.renderEndDate()}
      </>
    )
  }
  renderDropDown = (flag: any, newarray: any, value: any, func: any, text: any) => {

    return (
      <Box >
        <div style={{ position: 'relative', width: '100%' }}>

          <div style={{
            display: 'flex',
            padding: '15px',
            border: '1px solid #ccc',
            cursor: 'pointer',
            alignItems: 'center',
            borderRadius: '5px',
            justifyContent: 'space-between'
          }}
            data-test-id={text + "1"}
            onClick={() => this.setStateFlag(flag, text)}>
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }} >


              <Typography style={value ? {
                color: '#454545',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                marginLeft: '10px'
              } : {
                color: 'var(--Grey-Base, #939393)',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal', marginLeft: '10px'
              }}>
                {value || (text === "page" ? " " : text)}
                {text === "page" && " items per pages"}
              </Typography>
            </div>
            <ExpandLessIcon style={{color:'#707070'}}/>
          </div>
          {flag && (
            <div style={{
              position: 'absolute',
              bottom: '100%',
              left: '0',
              width: '100%',
              maxHeight: '200px', // Set max height to enable scrolling
              overflowY: 'auto', // Enable vertical scrolling
              backgroundColor: '#fff', /* Background color for options */
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
              zIndex: 100
            }}>
              {newarray.map((option: any, index: any) => (
                <div key={index} style={{
                  padding: '8px',
                  cursor: 'pointer',

                }}
                  data-test-id={text}
                  onClick={() => func(option)}>
                  <span style={{
                    color: '#585858', fontFamily: 'Jost',
                    fontSize: '14px', fontStyle: 'normal', fontWeight: 400,
                    lineHeight: 'normal'
                  }}>{option} {text === "page" && "items per pages"}</span>

                </div>
              ))}
            </div>
          )}
        </div>
      </Box>
    )
  }
  selectOptionMenu = (content: any) => {
    return (
      <div style={{ position: 'relative' }}>
        <EditOptionModalStyle>
          <React.Fragment>
            <Container className="container">
              <Grid item xs={12} >
                <Box style={{ display: "flex", cursor: 'pointer' }}
                  data-test-id="editbutton"
                  onClick={() => this.handleEditItemClick(content)}>
                  <img src={editIcon} />
                  <Typography
                    id="studyVal"
                    style={{
                      color: "#FFF",
                      fontFamily: "Jost",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600, marginLeft: '10px'
                    }}

                  >
                    Edit
                  </Typography></Box>
                <Box style={{ display: "flex", marginTop: '10px', cursor: 'pointer' }}
                  data-test-id="deletebutton"
                  onClick={() => this.showDailogDelete(content.id)}>
                  <img src={deleteIcon} />
                  <Typography
                    id="studyVal"
                    style={{
                      color: "#FFF",
                      fontFamily: "Jost",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600, marginLeft: '10px'
                    }}

                  >
                    Delete
                  </Typography>
                </Box>


              </Grid>
            </Container>
          </React.Fragment>

        </EditOptionModalStyle>
      </div>
    )
  }
  renderDrawerDetails = () => {
    return (
      <DrawerStyle anchor="right"
        open={this.state.showDetails}
        onClose={this.closeAppointmeentDetails}
      >
        <div style={{ width: 400, padding: '30px' }}>
          <Box >
            <IconButton style={{
              float: 'right',
              marginRight: "-10px",
              cursor: 'pointer',
              marginTop: '-20px'
            }} data-test-id="closeAppointment" onClick={() => this.closeAppointmeentDetails()}>
              <img src={buttonCancel} alt="buttonCancel" />
            </IconButton>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: "100%" }}>
            <Typography style={{
              color: '#292929',
              fontFamily: 'Jost',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '34px'
            }}>{this.state.SelectedAppointment.attributes?.title}</Typography>
          </Box>
          <Box>
            <Typography style={{
              color: '#8B8B8B',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
              marginTop: '10px'
            }}>{this.state.SelectedAppointment.attributes?.time_zone} </Typography>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Typography style={{
              color: '#000',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '22px',
              marginTop: '10px'
            }}>Start Date & Time </Typography>
          </Box>
          <Box style={{ marginTop: '20px', display: 'flex', }}>

            <img src={cal} />
            <Typography style={{ marginLeft: "20px" }}
            >{this.formattedDateStart(this.state.SelectedAppointment.attributes?.start_date)}</Typography>
            <Box>

              <Box style={{ display: 'flex', }}>
                <img src={time} style={{ marginLeft: "20px" }} />
                <Typography style={{ marginLeft: "20px" }}>{this.state.SelectedAppointment.attributes?.start_time}</Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Typography style={webStyle.dateNew}>End Date & Time </Typography>
          </Box>
          <Box style={{ marginTop: '20px', display: 'flex', }}>

            <img src={cal} />
            <Typography style={{ marginLeft: "20px" }}
            >{this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)}</Typography>
            <Box>
              <Box style={{ display: 'flex', }}>
                <img src={time} style={{ marginLeft: "20px" }} />
                <Typography style={{ marginLeft: "20px" }}>{this.state.SelectedAppointment.attributes?.end_time}</Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Typography style={webStyle.duration}>Duration </Typography>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Typography style={webStyle.hours}>{this.state.SelectedAppointment.attributes?.duration_hour} Hours {this.state.SelectedAppointment.attributes?.minute} Minute</Typography>
          </Box>

          <Box style={webStyle.box21}>

            <Typography style={{
              color: '#000',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '22px',
              marginTop: '10px'

            }}>Repeat</Typography>
            <Box>
              {this.state.SelectedAppointment.attributes?.recurring_meeting === "daily" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>Every day ,until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}
              {this.state.SelectedAppointment.attributes?.recurring_meeting === "weekly" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>Every week ,on {this.state.SelectedAppointment.attributes?.occurs_on} until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}
              {this.state.SelectedAppointment.attributes?.recurring_meeting === "monthly" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>Every month on the {this.state.SelectedAppointment.attributes?.on_day} of the month ,until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}
              {this.state.SelectedAppointment.attributes?.recurring_meeting === "yearly" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>Every {this.state.SelectedAppointment.attributes?.month} ,until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}

              {this.state.SelectedAppointment.attributes?.recurring_meeting === null && (<Typography style={{
                color: '#8B8B8B',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
                marginTop: '10px',
                width: "150px",
                padding: '5px',
                backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
              }}>Does Not Repeat</Typography>)}

{this.state.SelectedAppointment.attributes?.recurring_meeting === "custom" && (
  <>
   {this.state.SelectedAppointment.attributes?.custom_period === "Day" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>Every day ,until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}
               {this.state.SelectedAppointment.attributes?.custom_period === "Week" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>Every week ,on {this.state.SelectedAppointment.attributes?.occurs_on} until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}
              {this.state.SelectedAppointment.attributes?.custom_period === "Month" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>Every month on the {this.state.SelectedAppointment.attributes?.on_day} of the month ,until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}
              {this.state.SelectedAppointment.attributes?.custom_period === "Year" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>Every {this.state.SelectedAppointment.attributes?.month} ,until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}

  </>
)}
            </Box>
          </Box>
          <Box style={{ backgroundColor: '#ededeb', borderRadius: '10px', padding: "20px", marginTop: "20px" }}>

            <Typography style={webStyle.details}>Details</Typography>
            <Box style={{ width: '200px' }}>
              <TextField

                InputProps={{ disableUnderline: true }} // Disable underline
                style={{
                  color: 'var(--Grey-Base, #939393)',
                  fontFamily: 'Inter',
                  fontSize: '6px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
                multiline
                value={this.state.SelectedAppointment.attributes?.description}

                disabled
              />


            </Box>
            <Typography style={webStyle.addnew}>Address</Typography>
            <Box style={{ display: 'flex' }}>
              <img src={location} style={{
                width: "5%", height: "5%", marginRight: "5px", marginTop: '10px',
                marginBottom: '10px'
              }} />

              <Typography style={{
                color: '#8B8B8B',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
                marginTop: '10px',
                marginBottom: '10px'
              }}>{this.state.SelectedAppointment.attributes?.address}</Typography>
            </Box>
          </Box>

          <Box style={{ marginTop: '20px' }}>{
            this.state.SelectedAppointment.attributes?.patient_ids && this.state.SelectedAppointment.attributes?.patient_ids.length !== 0 && (
              <>
                <Box style={{ display: 'flex', }}>
                  <Box style={{
                    height: "30px",
                    flexShrink: 0, display: 'flex', alignItems: 'center',
                    backgroundColor: '#f2f3f5',
                    borderRadius: '15px',
                    padding: '3px'
                  }}>

                    <Typography style={{
                      color: '#454545',
                      fontFamily: 'Jost',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 200,
                      lineHeight: '26px', marginLeft: '5px', marginRight: '5px'
                    }}>{this.state.SelectedAppointment.attributes?.patient_ids.length} patients</Typography>
                  </Box>
                  <Box style={{
                    height: "30px",
                    flexShrink: 0, display: 'flex', alignItems: 'center',
                    backgroundColor: '#f2f3f5',
                    borderRadius: '15px',
                    padding: '3px', marginLeft: '10px'
                  }}>

                    <Typography style={{
                      color: '#454545',
                      fontFamily: 'Jost',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 200,
                      lineHeight: '26px', marginLeft: '5px', marginRight: '5px'
                    }}>{this.calculateYesEResponce(this.state.SelectedAppointment?.attributes?.patient_ids)} yes</Typography>
                  </Box>
                  <Box style={{
                    height: "30px",
                    flexShrink: 0, display: 'flex', alignItems: 'center',
                    backgroundColor: '#f2f3f5',
                    borderRadius: '15px',
                    padding: '3px', marginLeft: '10px'
                  }}>

                    <Typography style={{
                      color: '#454545',
                      fontFamily: 'Jost',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 200,
                      lineHeight: '26px', marginLeft: '5px', marginRight: '5px'
                    }}>{this.calculateNoResponce(this.state.SelectedAppointment?.attributes?.patient_ids)} no</Typography>
                  </Box>
                </Box>

                {this.state.SelectedAppointment.attributes?.patient_ids.map((item: any, index: any) => (
                  <Box style={{ marginTop: '20px' }}>
                    {this.renderParticipentsList(item, false)}
                  </Box>

                ))}

              </>
            )
          }

          </Box>
          <Box style={{ marginTop: '20px' }}
            data-test-id='attendence'
            onClick={this.callAttendence}>
            <Typography
              style={{
                color: '#A046FB',
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: 'Jost',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                textDecorationLine: 'underline',
              }}>Check & Mark Attendance</Typography>
          </Box>

        </div>

      </DrawerStyle>
    )
  }
  deleteDailog = () => {
    return (
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={this.state.deleteFlag}
        aria-labelledby="responsive-dialog-title" >
        <PasswordDialogStyle>
          <DialogActions>
            <IconButton data-test-id="cancel-password"
              onClick={() => this.setState({ deleteFlag: false })}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
            </IconButton>
          </DialogActions>
          <DialogTitle >
            <Typography className="dialog-title">Delete Schedule</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography className="dialog-para">
              Are you sure Do you want to Delete Selected Schedule
            </Typography></DialogContent>
          <div style={{ display: 'flex', margin: '20px', justifyContent: "space-between", }}>
            <Button variant="outlined" className="change-password-btn"
              onClick={() => this.handleDeleteItemClick()}
              type='reset'
              data-test-id="deleteschedule"
            >Delete</Button>
            <Button variant="outlined" className="change-password-btn"
              onClick={() => this.setState({ deleteFlag: false })}
              type='reset'
              data-test-id="cancelschedule"
            >Cancel</Button></div></PasswordDialogStyle>
      </Dialog>
    )
  }
  renderDrawerMenu = () => {
    const timezones = moment.tz.names();
    const today = new Date();
    return (
      <DrawerStyle anchor="right"
        open={this.state.open}
        onClose={this.handleDrawerCloseAdd}
      >
        <div style={{ width: 400, padding: '30px' }}>
          <Box >
            <IconButton style={{
              float: 'right',
              marginRight: "-10px",
              cursor: 'pointer',
              marginTop: '-20px'
            }} data-test-id="cancel-tech" onClick={() => this.handleDrawerCloseAdd()}>
              <img src={buttonCancel} alt="buttonCancel" />
            </IconButton>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: "100%" }}>
            <Typography style={{
              color: '#292929',
              fontFamily: 'Jost',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '34px'
            }}>{this.state.add_Schedule_Title}</Typography>
          </Box>
          <Box>
            <Typography style={{
              color: '#8B8B8B',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
              marginTop: '10px'
            }}>Time Zone :</Typography>
          </Box>
          <Box style={{ display: 'flex', marginTop: '10px' }}>

            <CustomTimeZone options={timezones} onSelect={this.handleSelectNew} onSet={this.handleFlag}/>
          </Box>
          {this.renderMilestoneDrawerView()}
          {this.renderTitleDesView()}

          <Box style={{ marginTop: '20px' }}>
            <CustomDropDownWithSearch
              edit={this.getData}
              options={this.state.patientList}
              onSelect={this.handleSelect}
              SelectedPatients={this.state.addPatientList}
              disableflag={this.state.disableFlag} />
            {this.state.addPatientList.length === 0 && (
              <>
                {this.state.patientError && (
                  <Typography style={webStyle.passworderror}>{this.state.patientErrorText}</Typography>)}
              </>)}
          </Box>

          {this.state.addPatientList && (

            <Box style={{ marginTop: '20px' }}>
              {this.state.addPatientList.map((item: any, index: any) => (
                this.renderParticipentsList(item, true)
              ))}
            </Box>

          )}




          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={2} >
              <Grid item xs={6} >
                <Box style={{
                  borderRadius: '4px',
                  border: '1px solid #E6E6E6',
                  padding: '8px'
                }}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    placeholder="Start Date"
                    format="DD/MM/YYYY"
                    value={this.state.selectedDate}
                    data-test-id="startdate"
                    onChange={this.handleDateChange}
                    autoOk
                    minDate={today}
                    InputAdornmentProps={{ position: "start" }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    keyboardIcon={<img src={cal} />} />

                </Box>  {this.state.startDateError && (
                  <Typography style={webStyle.passworderror}>{this.state.startDateErrorText}</Typography>)}
              </Grid>
              <Grid item xs={6}>
                <Box style={{
                  borderRadius: '4px',
                  border: '1px solid #E6E6E6',
                  padding: '8px'
                }} >
                  <KeyboardTimePicker
                    disableToolbar
                    variant="inline"
                    placeholder="Start Time"
                    autoOk
                    ampm={false}
                    value={this.state.selectedTime}
                    data-test-id="handletime"
                    onChange={this.handleTimeChange}
                    minutesStep={5} // Set the step for minutes
                    keyboardIcon={<img src={time} alt="Clock" />} // Custom keyboard icon
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}

                    InputAdornmentProps={{ position: "start" }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
                {this.state.startTimeError && (
                  <Typography style={webStyle.passworderror}>{this.state.startTimeErrorText}</Typography>)}
              </Grid>
              <Grid item xs={6}>
                {this.renderDropDown(this.state.openDuration,
                  this.state.durations, this.state.setDuration,
                  this.handleOptionClickDuration, "Duration Hour")}
                {this.state.durationError && (
                  <Typography style={webStyle.passworderror}>{this.state.durationErrorText}</Typography>)}
              </Grid>
              <Grid item xs={6}>
                {this.renderDropDown(this.state.openMinute,
                  this.state.minutes, this.state.setMinute,
                  this.handleOptionClickMinute, "Minute")}
                {this.state.minuteError && (
                  <Typography style={webStyle.passworderror}>{this.state.minuteErrorText}</Typography>)}

              </Grid>
            </Grid>
          </Box>

          <Box style={{ marginTop: '20px', display: 'flex', padding: '10px' }}>
            <FormControlLabel
              label=''
              control={
                <Checkbox style={{
                  borderRadius: '6.667px',
                  border: '1.667px solid #E6E6E6',
                  background: 'var(--White, #FFF)',
                }}
                  name='check'
                  value={this.state.checked}
                  icon={<span style={{
                    width: '4px',
                    height: '4px',
                    flexShrink: 0, padding: '1px'
                  }} />}
                  checkedIcon={<img src={tick} />} />}
              data-test-id="checkTick"
              disabled={this.state.selectedDate === null}
              onChange={(e) => this.checkedBox(e)}
            />
            <Typography style={{
              color: '#5A5A5A',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '26px',
            }}>
              Recurring Meeting
            </Typography>
          </Box>
          {this.state.checked && (
            <Box style={{ marginTop: '20px' }}>
              <CustomDropdown options={this.state.Reccurance} onSelect={this.hadleSelectReccurance} icons={false} selectedOptionSet={this.state.value} />

              {(this.state.value.toLowerCase()) === 'Daily'.toLowerCase() && (
                <>
                  {this.renderDailyDiv()}</>
              )}
              {(this.state.value.toLowerCase()) === 'Weekly'.toLowerCase() && (
                <>{this.renderWeeklyDiv()}</>
              )}
              {(this.state.value.toLowerCase()) === 'Monthly'.toLowerCase() && (
                <>{this.renderMonthlyDiv()}</>
              )}
              {(this.state.value.toLowerCase()) === 'Yearly'.toLowerCase() &&
                (
                  <>{this.renderYearlyDiv()}</>
                )}
              {(this.state.value.toLowerCase()) === 'Custom'.toLowerCase() && (
                <>{this.renderCustomDiv()}</>
              )}
            </Box>

          )}

          <Box style={{ marginTop: '20px' }}>
            <Typography style={{
              color: '#292929',
              fontFamily: 'Jost',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '26px',
            }}>Address</Typography>
          </Box>
          <Box style={{
            display: 'flex', paddingLeft: '12px',
            paddingRight: '8px',
            paddingTop: '4px',
            paddingBottom: '4px',
            border: '1px solid #ccc',
            cursor: 'pointer',
            alignItems: 'center',
            borderRadius: '5px',
            marginTop: '20px'
          }}>
            <img src={location} style={{ width: "4%", height: "4%", marginRight: "5px" }} />
            <TextField
              placeholder="Address Line 1"
              InputProps={{ disableUnderline: true }} // Disable underline
              style={webStyle.addresstext}
              value={this.state.address1}
              data-test-id="add1"
              onChange={this.changeAddress1}
            />

          </Box>
          <Typography style={webStyle.passworderror}>{this.state.erroradd1}</Typography>
          <Box style={{
            display: 'flex', paddingLeft: '12px',
            paddingRight: '8px',
            paddingTop: '4px',
            paddingBottom: '4px',
            border: '1px solid #ccc',
            cursor: 'pointer',
            alignItems: 'center',
            borderRadius: '5px',
            marginTop: '10px'
          }}>
            <img src={location} style={{ width: "4%", height: "4%", marginRight: "5px" }} />
            <TextField
              placeholder="Address Line 2"
              InputProps={{ disableUnderline: true }} // Disable underline
              style={{
                color: 'var(--Grey-Base, #939393)',
                fontFamily: 'Inter',
                fontSize: '6px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal', marginLeft: '10px'
              }}
              value={this.state.address2}
              data-test-id="add2"
              onChange={this.changeAddress2}
            />

          </Box>
          <Typography style={webStyle.passworderror}>{this.state.erroradd2}</Typography>
          <Box style={{
            display: 'flex', paddingLeft: '12px',
            paddingRight: '8px',
            paddingTop: '4px',
            paddingBottom: '4px',
            border: '1px solid #ccc',
            cursor: 'pointer',
            alignItems: 'center',
            borderRadius: '5px',
            marginTop: '10px'
          }}>
            <img src={location} style={{ width: "4%", height: "4%", marginRight: "5px" }} />
            <TextField
              placeholder="Address Line 3"
              InputProps={{ disableUnderline: true }} // Disable underline
              style={{
                color: 'var(--Grey-Base, #939393)',
                fontFamily: 'Inter',
                fontSize: '6px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal', marginLeft: '10px'
              }}
              value={this.state.address3}
              data-test-id="add3"
              onChange={this.changeAddress3}
            />

          </Box>
          {this.state.addressError && (
            <Typography style={webStyle.passworderror}>{this.state.addressErrorText}</Typography>)}
          <Typography style={webStyle.passworderror}>{this.state.erroradd3}</Typography>
          <Box style={{ marginTop: "20px", marginBottom: '80px', display: 'flex', }}>
            <Box style={{
              borderRadius: '8px',
              border: '2px solid #A046FB', padding: '5px', cursor: 'pointer'
            }}
              data-test-id="saveclick"
              onClick={this.SelectMethod}>
              <Typography style={{
                color: '#2B2B2B',
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: 'Jost',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '28px',
              }}>
                Save
              </Typography>
            </Box>
            <Box style={{
              borderRadius: '8px',
              border: '2px solid #8F8F8F', padding: '5px', marginLeft: '20px', cursor: 'pointer'
            }}
              data-test-id="cancelclick"
              onClick={() => { this.setState({ open: false }) }}>
              <Typography style={{
                color: '#2B2B2B',
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: 'Jost',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '28px',
              }}>
                Cancel
              </Typography>
            </Box>
          </Box>
        </div>

      </DrawerStyle>
    )
  }
  renderPreviousSchedule = () => {
    return (<Box style={{ marginTop: '20px', overflowX: 'hidden'}}>
      {this.state.previous && this.state.previous.length !== 0 && (
        <> {this.rendergridView(this.state.previous, "Previous")}
        </>
      )}


    </Box>)
  }
  renderUpcommingSchedule = () => {
    return (<Box style={{ marginTop: '20px', overflowX: "hidden" }}>
      {this.state.upcomming && this.state.upcomming.length !== 0 && (
        <>{this.rendergridView(this.state.upcomming, "Upcomming")}
        </>
      )}

    </Box>)
  }
  eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
    const style = {
      backgroundColor: '#352351',
    };

    return {
      style: style
    };
  };
  EventComponent = ({ event }: any) => {
    const { title,  address } = event;

    return (
      <div style={{
        backgroundColor: '#352351',
        padding: '10px',
        color: 'white',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      }}>
        <Typography style={{
          color: '#fff',
          fontFamily: 'Inter',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '22px',
          marginTop: '10px',
          marginBottom: '10px'
        }}>{title}</Typography>
        <Box style={{ display: 'flex' }}>
          <img src={white_loc} style={{
            width: "10%", height: "10%", marginRight: "5px", marginTop: '10px',
            marginBottom: '10px'
          }} />

          <Typography style={{
            color: '#fff',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            marginTop: '10px',
            marginBottom: '10px'
          }}>{address}</Typography>
        </Box>

      </div>
    );
  };
  sideBar =()=>{
    return(
      <SideNavbar
          navigation={this.props.navigation}
          id={this.props.id}
          showStudy={true}
          showButtonChats
          showButtonNotification
        />
    )
  }
  Attendence = () =>{
    return(
      <>
      {this.state.attendencePatientList && (
        <Box style={webStyle.displayflex1}
        >
          <CustomPagination data={this.state.attendencePatientList} itemsPerPage={this.state.itemsPerPage}
            edit={this.getUpdatedData} data-testid="custom-pagination" />

          <Box>
            {this.renderDropDown(this.state.openPages,
              this.state.pageCount, this.state.setPages,
              this.handleitemsPerPage, "page")}
          </Box>
        </Box>
      )}
      </>
    )
  }
  SelectTab=()=>{
    return(
      <>
      {this.state.selectedTab === "Previous" && (
        <>
          {
            this.renderPreviousSchedule()
          }
        </>
       )}
      </>
    )
  }
  Phonenumber = (row:any)=>{
    return(
      <Typography style={webStyle.descStyel}>
        {row.country_code ? `+${row.country_code} ` : ''}{row.phone_number}
      </Typography>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    return (
      <>
        {this.sideBar()}

        <Box style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
          <Sidebar navigation={this.props.navigation} id={this.props.id} selected="visit_schedule" />
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            {this.state.myCalenderFlag ? (
              <Box
                style={webStyle.mainBox}>
                <Box style={webStyle.boxNew}>
                  <Typography style={webStyle.box2}>Calendar</Typography>
                  <Box style={webStyle.box3}>
                    <Button
                      variant="contained"
                      style={webStyle.imgButton2}
                      data-test-id='AddSchedule'
                      onClick={this.toggleDrawer}
                      startIcon={<AddIcon style={webStyle.imgButtonIcon2}/>}
                    >
                      Add New Schedule
                    </Button>
                  </Box>
                </Box>
                <Box style={{
                  marginBottom: "10px"
                }}>
                  <Typography style={webStyle.para}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Typography>
                </Box>
                <Box style={{ marginTop: '20px', display: 'flex',alignItems:'center',justifyContent:"center",gap:'20px' }}>
                  <Button
                    onClick={this.openCalender}
                    variant="outlined"
                    style={styles.backButton}
                    startIcon={<KeyboardBackspaceIcon style={{ color: 'black' }} />}
                  >
                      Back
                  </Button>
                  <Grid style={styles.sideBackButton}>
                    <Typography style={styles.btnVisit}>
                      Visit Schedule
                    </Typography>
                    <ArrowRightIcon style={styles.textIcon} />
                    <Typography style={styles.btnCalender}>
                      Calender
                    </Typography>
                  </Grid>

                </Box>
                <Box style={{ marginTop: "20px", width: '100%' }}>

                  <Calendar
                    localizer={localizer}
                    events={this.state.scheduleOnCalender}
                    defaultView="week"
                    defaultDate={new Date()}
                    style={{ marginBottom: '50px',height:'100vh' }}
                    onNavigate={this.handleNavigate}
                    onSelectEvent={this.handleEventClick}
                    eventPropGetter={this.eventStyleGetter}
                    components={{
                      event: this.EventComponent, // Custom event component
                    }}
                    views={['month', 'week', 'day']}
                  />
                  
                  {this.state.selectedEvent && (
                    <div style={{
                      width: 350, padding: '30px', position: 'absolute',
                      top: '75%',
                      left: '50%',
                      backgroundColor: '#fff',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.8)',
                      borderRadius: '5px',
                      zIndex: 100
                    }}>
                      <IconButton style={{
                        float: 'right',
                        marginRight: "-10px",
                        cursor: 'pointer',
                        marginTop: '-20px'
                      }}
                        data-test-id="viewclose"
                        onClick={() => this.setState({ selectedEvent: null })}>
                        <img src={buttonCancel} alt="buttonCancel" />
                      </IconButton>
                      <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: "100%" }}>
                        <Typography style={{
                          color: '#292929',
                          fontFamily: 'Jost',
                          fontSize: '24px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '34px'
                        }}>{this.state.selectedEvent.title}</Typography>

                      </Box>
                      <Box>
                        <Typography style={{
                          color: '#8B8B8B',
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '22px',
                          marginTop: '10px'
                        }}>{this.state.selectedEvent.time_zone} </Typography>
                      </Box>
                      <Box style={{ marginTop: '20px' }}>
                        <Typography style={{
                          color: '#000',
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: '22px',
                          marginTop: '10px'
                        }}>Start Date & Time </Typography>
                      </Box>
                      <Box style={{ marginTop: '20px', display: 'flex', }}>

                        <img src={cal} />
                        <Typography style={{ marginLeft: "20px" }}
                        >{this.formattedDateStart(this.state.selectedEvent.ssdate)}</Typography>
                        <Box>

                          <Box style={{ display: 'flex', }}>
                            <img src={time} style={{ marginLeft: "20px" }} />
                            <Typography style={{ marginLeft: "20px" }}>{this.state.selectedEvent.sstime}</Typography>

                          </Box>


                        </Box>

                      </Box>
                      <Box style={{ marginTop: '20px' }}>
                        <Typography style={webStyle.dateNew}>End Date & Time </Typography>
                      </Box>
                      <Box style={{ marginTop: '20px', display: 'flex', }}>

                        <img src={cal} />
                        <Typography style={{ marginLeft: "20px" }}
                        >{this.formattedDateStart(this.state.selectedEvent.eedate)}</Typography>
                        <Box>

                          <Box style={{ display: 'flex', }}>
                            <img src={time} style={{ marginLeft: "20px" }} />
                            <Typography style={{ marginLeft: "20px" }}>{this.state.selectedEvent.eetime}</Typography>

                          </Box>


                        </Box>

                      </Box>
                      <Box style={{ marginTop: '20px' }}>
                        <Typography style={webStyle.duration}>Duration </Typography>
                      </Box>
                      <Box style={{ marginTop: '20px' }}>
                        <Typography style={webStyle.hours}>{this.state.selectedEvent.duration_hour} Hours {this.state.selectedEvent.minute} Minute</Typography>
                      </Box>


                      <Box style={{ backgroundColor: '#ededeb', borderRadius: '10px', padding: "20px", marginTop: "20px" }}>


                        <Typography style={webStyle.addnew}>Address</Typography>
                        <Box style={{ display: 'flex' }}>
                          <img src={location} style={{
                            width: "5%", height: "5%", marginRight: "5px", marginTop: '10px',
                            marginBottom: '10px'
                          }} />

                          <Typography style={{
                            color: '#8B8B8B',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '22px',
                            marginTop: '10px',
                            marginBottom: '10px'
                          }}>{this.state.selectedEvent.address}</Typography>
                        </Box>
                      </Box>
                      <Box style={{ marginTop: '20px' }}>{
                        this.state.selectedEvent.patient_ids && this.state.selectedEvent.patient_ids.length !== 0 && (
                          <>
                            <Box style={{ display: 'flex', }}>
                              <Box style={{
                                height: "30px",
                                flexShrink: 0, display: 'flex', alignItems: 'center',
                                backgroundColor: '#f2f3f5',
                                borderRadius: '15px',
                                padding: '3px'
                              }}>

                                <Typography style={{
                                  color: '#454545',
                                  fontFamily: 'Jost',
                                  fontSize: '14px',
                                  fontStyle: 'normal',
                                  fontWeight: 200,
                                  lineHeight: '26px', marginLeft: '5px', marginRight: '5px'
                                }}>{this.state.selectedEvent.patient_ids.length} patients</Typography>
                              </Box>
                              <Box style={{
                                height: "30px",
                                flexShrink: 0, display: 'flex', alignItems: 'center',
                                backgroundColor: '#f2f3f5',
                                borderRadius: '15px',
                                padding: '3px', marginLeft: '10px'
                              }}>

                                <Typography style={{
                                  color: '#454545',
                                  fontFamily: 'Jost',
                                  fontSize: '14px',
                                  fontStyle: 'normal',
                                  fontWeight: 200,
                                  lineHeight: '26px', marginLeft: '5px', marginRight: '5px'
                                }}>{this.calculateYesEResponce(this.state.selectedEvent?.patient_ids)} yes</Typography>
                              </Box>
                              <Box style={{
                                height: "30px",
                                flexShrink: 0, display: 'flex', alignItems: 'center',
                                backgroundColor: '#f2f3f5',
                                borderRadius: '15px',
                                padding: '3px', marginLeft: '10px'
                              }}>

                                <Typography style={{
                                  color: '#454545',
                                  fontFamily: 'Jost',
                                  fontSize: '14px',
                                  fontStyle: 'normal',
                                  fontWeight: 200,
                                  lineHeight: '26px', marginLeft: '5px', marginRight: '5px'
                                }}>{this.calculateNoResponce(this.state.selectedEvent?.patient_ids)} no</Typography>
                              </Box>
                            </Box>

                            {this.state.selectedEvent.patient_ids.map((item: any, index: any) => (
                              <Box style={{ marginTop: '20px' }}>
                                {this.renderParticipentsList(item, false)}
                              </Box>

                            ))}

                          </>
                        )
                      }

                      </Box>
                      <Box style={{ marginTop: '20px' }}
                        data-test-id='attendence'
                      >
                        <Typography
                          style={{
                            color: '#A046FB',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: 'Jost',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textDecorationLine: 'underline',
                          }}>Send Notification</Typography>
                      </Box>

                    </div>
                  )}


                </Box>
                {this.renderDrawerMenu()}
              </Box>
            ) : (
              <>
                {
                  this.state.openAttendence ? (
                    <>
                      <Box
                        style={webStyle.mainBox}>
                        <Box style={webStyle.boxNew}>
                          <Typography style={webStyle.box2}>Attendance</Typography>

                        </Box>
                        <Box>
                          <Typography style={webStyle.para}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Typography>
                        </Box>




                        <div style={{ display: 'flex', marginTop: '20px' }}>
                          <Button
                            data-test-id="closeAttendence"
                            onClick={this.closeAttendence}
                            variant="outlined"
                            style={{
                              borderRadius: 5,
                              border: "2px solid #A046FB",
                              textTransform: 'none'
                            }}
                            startIcon={<KeyboardBackspaceIcon style={{ color: 'black' }} />}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                color: "#292929",
                                fontSize: 18,
                                fontWeight: 600,
                                fontFamily: 'Inter'
                              }}
                            >
                              Back
                            </Typography>
                          </Button>
                          <Breadcrumbs
                            style={{
                              display: 'flex',
                              flex: 1,
                              alignItems: 'center',
                              paddingLeft: 16,
                              marginLeft: 16,
                              background: 'rgba(192, 192, 192, 0.2)',
                              borderRadius: 10,
                            }}
                            separator={<ArrowRightIcon style={{ color: '#A046FB' }} />}
                            aria-label="breadcrumb">
                            <Button>
                              <Typography style={{
                                fontSize: 16,
                                fontWeight: 500,
                                color: 'rgba(192, 192, 192, 0.9)',
                                textTransform: 'none'
                              }}>
                                Visit Schedule
                              </Typography>
                            </Button>
                            <Button>
                              <Typography style={{
                                fontSize: 16,
                                fontWeight: 500,
                                color: 'rgba(192, 192, 192, 0.9)',
                                textTransform: 'none'
                              }}>
                                {this.state.SelectedAppointment.attributes.title}
                              </Typography>
                            </Button>
                            <Button>
                              <Typography style={{
                                fontSize: 16,
                                fontWeight: 500,
                                color: 'rgba(192, 192, 192, 0.9)',
                                textTransform: 'none'
                              }}>
                                Attendence
                              </Typography>
                            </Button>
                          </Breadcrumbs>
                        </div>
                        <Box style={{ marginTop: "20px" }}>
                          <Box
                            style={webStyle.detailsGrid}
                          >
                            <Grid container spacing={2} className="formContainer"
                              style={{ padding: "30px", marginTop: "20px", height: '255px' }}
                            >
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <div >
                                    <div style={webStyle.displayflex}>
                                      <Typography style={webStyle.titleStyle}>
                                        {this.state.SelectedAppointment.attributes.title}
                                      </Typography>

                                    </div>
                                    <div
                                      style={{
                                        borderTop: '1px solid #cbcdd1',
                                        width: '100%',
                                        marginTop: '10px'
                                      }} >
                                    </div>
                                  </div>


                                </Grid>
                                <Grid item xs={3}>
                                  <div >
                                    <Typography style={webStyle.titleStyle}>
                                      Date :
                                    </Typography>
                                    <Typography style={webStyle.descStyel}>
                                      {this.callDateChangeFormat(this.state.SelectedAppointment.attributes.start_date)}

                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div > <Typography style={webStyle.titleStyle}>
                                    Time :
                                  </Typography>
                                    <Typography style={webStyle.descStyel}>
                                      {this.state.SelectedAppointment.attributes.start_time} to {this.state.SelectedAppointment.attributes?.end_time}

                                    </Typography></div>
                                </Grid>
                                <Grid item xs={12}>
                                  <div > <Typography style={webStyle.titleStyle}>
                                    Address :
                                  </Typography>
                                    <Typography style={webStyle.descStyel}>
                                      {this.state.SelectedAppointment.attributes.address}
                                    </Typography></div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                        <Box style={{ marginTop: '20px' }}>
                          <Grid container >
                            <Grid item xs={12} style={{
                              backgroundColor: 'rgb(238, 236, 232)',
                              borderRadius: '10px',
                            }} >
                              <Grid container style={{
                                paddingBottom: '10px',
                                paddingTop: '10px',
                                marginLeft: '10px',
                              }}>
                                <Grid item xs={2}>
                                  <Typography style={webStyle.titleStyle}>Patient Name</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography style={webStyle.titleStyle}>Invite Response</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography style={webStyle.titleStyle}>Phone Number</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography style={webStyle.titleStyle}>Patient Response</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography style={webStyle.titleStyle}>Coordinator Response</Typography>
                                </Grid>
                              </Grid>

                            </Grid>
                            {this.state.attendencePatientList.map((row: any) => (

                              <Grid item xs={12} key={row.id}>

                                <Grid container style={{
                                  paddingBottom: '10px',
                                  paddingTop: '10px',
                                  marginLeft: '10px',
                                }}>
                                  <Grid item xs={2} style={
                                    { display: 'flex', alignItems: "center" }
                                  }>
                                    <Typography style={webStyle.descStyel}>{row.full_name}</Typography>
                                  </Grid>
                                  <Grid item xs={2} style={
                                    { display: 'flex', alignItems: "center" }
                                  }>
                                    <Typography style={webStyle.descStyel}>{row.invite_response}</Typography>
                                  </Grid>
                                  <Grid item xs={2} style={
                                    { display: 'flex', alignItems: "center" }
                                  }>
                                    {this.Phonenumber(row)}
                                  </Grid>
                                  <Grid item xs={2} style={
                                    { display: 'flex', alignItems: "center" }
                                  }>
                                    <Typography style={webStyle.descStyel}>{this.state.patientResponses[row.id] ? this.state.patientResponses[row.id] : ''}</Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <div className="dropdown" style={{
                                      display: 'flex',
                                      padding: '10px',
                                      cursor: 'pointer',
                                      alignItems: 'center',
                                      justifyContent: 'space-between'
                                    }}>
                                      <div className="dropdown-toggle"
                                        data-test-id="dropdownattendence"
                                        onClick={() => this.callSetResponce(row)}
                                        style={{ alignItems: 'center', display: 'flex' }}
                                      >
                                        <Typography style={webStyle.descStyel}>{this.state.coordinatorResponses[row.id] ? this.state.coordinatorResponses[row.id] : ''}</Typography>
                                        <img src={down} style={{ marginLeft: '10px' }} />
                                      </div>
                                      {this.state.coordinatorResp &&
                                        this.state.attendenceID === row.id && (
                                          <div className="dropdown-options" style={{
                                            position: 'absolute',
                                            backgroundColor: '#fff',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                            borderRadius: '5px',
                                            top: '675px'
                                          }}>
                                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                              <Radio
                                                value="attended"
                                                name="selectCoordinatorResp"
                                                color="primary"
                                                size='small'
                                                data-test-id="radioA"
                                                checked={this.state.selectCoordinatorResp === 'Attended'}
                                                onChange={() => this.handleRadioChange('Attended', row)}
                                              />
                                              <Typography style={{
                                                color: '#454545',
                                                fontFamily: 'Jost',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                              }}> Attended</Typography>
                                            </label>
                                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                              <Radio
                                                value="not-attended"
                                                name="selectCoordinatorResp"
                                                size="small"
                                                data-test-id="radioB"
                                                checked={this.state.selectCoordinatorResp === 'Not Attended'}
                                                onChange={() => this.handleRadioChange('Not Attended', row)}
                                              />
                                              <Typography style={webStyle.descStyel}> Not Attended</Typography>
                                            </label>
                                          </div>
                                        )}
                                    </div>

                                  </Grid>

                                </Grid>

                              </Grid>
                            ))}
                          </Grid>
                          {this.Attendence()}
                        </Box>
                      </Box>
                    </>
                  ) : (<>
                    <Box
                      style={webStyle.mainBox}>
                      <Box style={webStyle.boxNew}>
                        <Typography style={webStyle.box2}>Visit Schedule</Typography>
                        <Box style={webStyle.box3}>
                          <img src={Add_schedule} style={webStyle.imag1} data-test-id='calender'
                            onClick={this.openCalender} />
                          <img src={My_Calender} style={webStyle.img2}
                            data-test-id='AddSchedule'
                            onClick={this.toggleDrawer} />
                        </Box>
                      </Box>

                      <Box style={{
                        marginBottom: "10px"
                      }}>
                        <Typography style={webStyle.para}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Typography>
                      </Box>
                      {this.renderMilestoneView()}
                      <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: '20px' }}>
                        <GenericTabs
                          data-test-id="generic_tab"
                          tabs={this.state.tabs}
                          handleSelectTab={this.handleSelectTab}
                          selected={this.state.selectedTab}
                        />

                      </Box>

                      {this.SelectTab()}
                      {this.state.selectedTab === "Upcoming" && (
                        <>
                          {this.renderUpcommingSchedule()}
                        </>

                      )}

                      {this.renderDrawerMenu()}
                      {this.renderDrawerDetails()}
                      {this.deleteDailog()}
                    </Box>
                  </>)
                }
              </>

            )}

          </MuiPickersUtilsProvider>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  backButton : {
    borderRadius: 5,
    textTransform: 'none' as 'none',
    border: "2px solid #A046FB",
    fontFamily: 'Inter' as "Inter",
    fontWeight: 600,
    color: "#292929",
  },
  sideBackButton: {display:'flex',alignItems:'center',width:'100%',backgroundColor:'rgb(249, 241, 241)',padding:'10px'},
  btnVisit: {color:"#939aad",fontFamily: 'Jost' as 'Jost',fontSize:'14px'},
  textIcon : {color:'#a046fb'},
  btnCalender : {color:"#939aad",fontSize:'14px',fontFamily: 'Jost'}

}
const EditOptionModalStyle = styled((Box as React.FC<BoxProps>))({

  "& .MuiOutlinedInput-input": {
    padding: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    height: "38px",
    color: "#D4D3D3",
    fontFamily: "Jost",
    paddingLeft: '16px',
  }, "& .topBox": {
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .selectLogo": {
    verticalAlign: 'middle',
    float: 'right',
  },
  "& .searchStudy": {
    borderRadius: "4px",
    border: "1px solid #474747",
    height: "38px",
    cursor: 'pointer',
    color: "#D4D3D3",
    fontFamily: "Jost",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px"

  },
  "& .studyName.selected": {
    fontStyle: "normal",
    fontWeight: 600,
    color: "#FFF",
    fontFamily: "Jost",
    fontSize: "14px",
  },
  "& .studyName": {
    paddingTop: '16px',
    fontStyle: "normal",
    fontWeight: 400,
    cursor: 'pointer',
    color: "#D4D3D3",
    fontFamily: "Jost",
    fontSize: "14px",
  },
  "& .container": {
    background: "#2F2F2F",
    boxShadow: "none",
    position: "absolute",
    top: "10.4%",
    right: "18.4%",
    padding: '20px 30px',
    maxWidth: '700px',
    width: '100%',
    borderRadius: "8px",
    "&::after": { 
      content: "''",
      position: "absolute",
      top: "-10px", 
      right: "15px", 
      width: "0",
      height: "0",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #2F2F2F",
    },
    "@media (max-width: 1366px)": {
      maxWidth: '500px',
      left: "auto",
      width: "auto",
    },
    "@media (max-width: 1199px)": {
      maxWidth: '400px',
      left: "auto",
      width: "auto",
    },
    "@media (max-width: 991px)": {
      maxWidth: '300px',
      left: "auto",
      width: "auto",
    },
    "@media (max-width: 691px)": {
      maxWidth: '200px',
      left: "auto",
      width: "auto",
    },
    "@media (max-height: 1366px)": {
      top: "auto",
      left: "auto",
      width: "auto",
    },
    "@media (max-height: 1199px)": {
      top: "auto",
      maxWidth: '174px',
      left: "auto",
      width: "auto",

    },
    "@media (max-height: 991px)": {
      top: "auto",
      left: "auto",
      width: "auto",
    },
    "@media (max-height: 600px)": {
      top: "auto",
    },
    "@media (max-height: 400px)": {
      top: "auto",
    },
    "@media (min-width: 1600px)": {
      left: "auto",
      width: "auto",
    },
    "@media (min-width: 1920px)": {
      top: "auto",
      left: "auto",
      width: "auto",
    }
  },



})
const DrawerStyle = styled(Drawer)<DrawerProps>({
  height: "calc(100vh - 90px)",
  "& .MuiDrawer-paper": {
    borderRadius: '10px 0 0 10px',
  },
  "& .MuiBackdrop-root": {
    backgroundColor: 'transparent'
  }
})
const PasswordDialogStyle = styled(Box)({
  "& .passwordError": {
    maxWidth: "300px",
    fontSize: "12px",
    color: "#F44336",
    fontFamily: "Inter",
    marginTop: "5px",
    marginLeft: "14px"
  },
  "& .dialog-title": {
    color: "#000",
    fontSize: "28px",
    fontWeight: "bolder",
    fontFamily: "Jost",
  },
  "& .MuiDialogContent-root": {
    padding: '8px 40px'
  },

  "& .MuiDialogTitle-root": {
    padding: '0 40px'
  },
  "& .dialog-para": {
    fontFamily: "Jost",
    fontSize: "16px",
    color: "#5E5E5E",
    fontWeight: 400,
    fontStyle: "normal",
    width: '90%'
  },
  "& .change-password-btn": {
    height: '50px',
    width: '45%',
    color: '#000',
    fontFamily: 'Jost',
    fontSize: '14px',
    fontWeight: 'bolder',
    textTransform: 'capitalize',
    border: "2px solid #A046FB",
    marginBottom: '40px',
    borderRadius: "4px",
    marginTop: '32px',
    background: "#FFF",
    boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)"
  },
  "& .oldPswd": {
    fontFamily: 'Jost',
    height: '49px',
    marginTop: '24px',
  },
  "& .cNewPswd": {
    marginTop: '20px',
    height: '49px',
    fontFamily: 'Jost',
  },
  "& .newPswd": {
    height: '49px',
    marginTop: '20px',
    fontFamily: 'Jost',

  },
})
const webStyle = {
  mainBox: {
    width: "100%",
    padding: "30px"
  },
  addnew: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    marginTop: '10px'
  },
  details: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    marginTop: '10px'
  },
  box21: { backgroundColor: '#ededeb', height: '85px', borderRadius: '10px', padding: "20px", marginTop: "20px" },
  hours: {
    color: '#8B8B8B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: '10px'
  },
  duration: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    marginTop: '10px'
  },
  dateNew: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    marginTop: '10px'
  },
  addresstext: {
    color: 'var(--Grey-Base, #939393)',
    fontFamily: 'Inter',
    fontSize: '6px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal', marginLeft: '10px'
  },
  passworderror: {
    maxWidth: "300px",
    fontSize: "12px",
    color: "#F44336",
    fontFamily: "Inter",
    marginTop: "5px",

  },
  titleStyle: {
    color: '#1F3650',
    fontFamily: 'Jost',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '26px'
  },
  descStyel: {
    color: '#454545',
    fontFamily: 'Jost',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px'
  },
  openmilestone: {
    display: 'flex',
    padding: '10px',
    border: '1px solid #ccc',
    cursor: 'pointer',
    alignItems: 'center',
    borderRadius: '5px',
    justifyContent: 'space-between'
  },
  displayflex1: { display: 'flex', justifyContent: 'space-between', marginTop: '20px' },
  displayflex: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  detailsGrid: {
    backgroundColor: '#ededeb',
    borderRadius: '10px',

  },

  required: {
    color: '#838383',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
  },
  fullname: {
    color: '#292929',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Jost',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  avatarstyle: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: '#F6EEFF', 
    color: 'black',
  },
  datePicker: {
    '& .MuiInputBase-inputAdornedStart': {
      paddingLeft: 0, 
    },
    '& .MuiInputBase-root': {
      paddingLeft: 0, 
    },
  },
  pagination: {
    '& ul': {
      justifyContent: 'center',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0, 
    },
    '& .Mui-selected': {
      backgroundColor: '#f50057',
      color: '#fff',
    }, '& .MuiButtonBase-root': {
      backgroundColor: '#f50057',
      color: '#fff',
    }
  },
  boxNew: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  box2: {
    color: "#1F3650",
    fontSize: 40,
    fontWeight: 700,
    marginBottom: 30
  },
  box3: {
    display: 'flex',
    justifyContent: 'right',
    height: "8%",
    marginLeft: '10px',
  }, imag1: {
    width: '128px',
    height: '44px',
    flexShrink: 0,
    marginRight: "10px"
  },
  img2:
  {
    width: '200px',
    height: '44px',
    flexShrink: 0,
  },
  imgButton2: {
    width: '100%',
    boxShadow:'none',
    backgroundColor:'#ffff',
    border:'2px solid #a046fb',
    fontSize:'18px',
    fontFamily:'Jost',
    textTransform:'none' as 'none',
    fontWeight:600,
    borderRadius: '8px'
  },
  imgButtonIcon2: {
    fontSize:'20px'
  },
  para: {
    color: '#414141',
    fontFamily: 'Jost',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
  },
  tab1: {
    color: '#A046FB',
    fontFamily: 'Jost',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '26px',
  }, tab2: {
    color: '#A046FB',
    fontFamily: 'Jost',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '26px',
  },
  drawerPaper: {
    backgroundColor: 'transparent',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
};
// Customizable Area End
