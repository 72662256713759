import React, { CSSProperties } from "react";
import { Box, Modal, Typography } from "@material-ui/core";
import SidebarController from "./SidebarController";
import {
  dashboardIcon,
  patientIcon,
  taskListIcon,
  visitScheduleIcon,
  sendNotificationIcon,
  communityIcon,
} from "./assets";

class Sidebar extends SidebarController {
  render() {
    return (
      <>
      <Box
        style={{
          backgroundColor: "#5E2A95",
          flex: "0 0 15%",
          borderTopRightRadius: 15,
          paddingRight: 20,
          alignItems: "center",
        }}
      >
        <Box
          style={
            this.state.selected === "dashboard"
              ? (webStyle.box("white") as CSSProperties)
              : (webStyle.box("#5E2A95") as CSSProperties)
          }
          onClick={() => this.selectTab("dashboard")}
        >
          <img
            src={dashboardIcon}
            alt=""
            width={20}
            height={20}
            style={
              this.state.selected === "dashboard"
                ? webStyle.svgImage(
                    "brightness(1) invert(0.8) sepia(1) saturate(5) hue-rotate(250deg)"
                  )
                : webStyle.svgImage("brightness(0) invert(1)")
            }
          />
          <Typography
            style={
              this.state.selected === "dashboard"
                ? webStyle.text("#5E2A95", 600)
                : webStyle.text("white")
            }
          >
            Dashboard
          </Typography>
        </Box>

        <Box
          style={
            this.state.selected === "patient"
              ? (webStyle.box("white") as CSSProperties)
              : (webStyle.box("#5E2A95") as CSSProperties)
          }
          onClick={() => this.selectTab("patient")}
        >
          <img
            src={patientIcon}
            alt=""
            width={20}
            height={20}
            style={
              this.state.selected === "patient"
                ? webStyle.svgImage(
                    "brightness(1) invert(0.8) sepia(1) saturate(5) hue-rotate(250deg)"
                  )
                : webStyle.svgImage("brightness(0) invert(1)")
            }
          />
          <Typography
            style={
              this.state.selected === "patient"
                ? webStyle.text("#5E2A95", 600)
                : webStyle.text("white")
            }
          >
            Patient
          </Typography>
        </Box>

        <Box
          style={
            this.state.selected === "task_list"
              ? (webStyle.box("white") as CSSProperties)
              : (webStyle.box("#5E2A95") as CSSProperties)
          }
          onClick={() => this.selectTab("task_list")}
        >
          <img
            src={taskListIcon}
            alt=""
            width={20}
            height={20}
            style={
              this.state.selected === "task_list"
                ? webStyle.svgImage(
                    "brightness(1) invert(0.8) sepia(1) saturate(5) hue-rotate(250deg)"
                  )
                : webStyle.svgImage("brightness(0) invert(1)")
            }
          />
          <Typography
            style={
              this.state.selected === "task_list"
                ? webStyle.text("#5E2A95", 600)
                : webStyle.text("white")
            }
          >
            Task List
          </Typography>
        </Box>

        <Box
          style={
            this.state.selected === "visit_schedule"
              ? (webStyle.box("white") as CSSProperties)
              : (webStyle.box("#5E2A95") as CSSProperties)
          }
          onClick={() => this.selectTab("visit_schedule")}
        >
          <img
            src={visitScheduleIcon}
            alt=""
            width={20}
            height={20}
            style={
              this.state.selected === "visit_schedule"
                ? webStyle.svgImage(
                    "brightness(1) invert(0.8) sepia(1) saturate(5) hue-rotate(250deg)"
                  )
                : webStyle.svgImage("brightness(0) invert(1)")
            }
          />
          <Typography
            style={
              this.state.selected === "visit_schedule"
                ? webStyle.text("#5E2A95", 600)
                : webStyle.text("white")
            }
          >
            Visit Schedule
          </Typography>
        </Box>

        <Box
          style={
            this.state.selected === "send_notification"
              ? (webStyle.box("white") as CSSProperties)
              : (webStyle.box("#5E2A95") as CSSProperties)
          }
          onClick={() => this.selectTab("send_notification")}
        >
          <img
            src={sendNotificationIcon}
            alt=""
            width={20}
            height={20}
            style={
              this.state.selected === "send_notification"
                ? webStyle.svgImage(
                    "brightness(1) invert(0.8) sepia(1) saturate(5) hue-rotate(250deg)"
                  )
                : webStyle.svgImage("brightness(0) invert(1)")
            }
          />
          <Typography
            style={
              this.state.selected === "send_notification"
                ? webStyle.text("#5E2A95", 600)
                : webStyle.text("white")
            }
          >
            Send Notification
          </Typography>
        </Box>

        <Box
          style={
            this.state.selected === "community"
              ? (webStyle.box("white") as CSSProperties)
              : (webStyle.box("#5E2A95") as CSSProperties)
          }
          onClick={() => this.selectTab("community")}
        >
          <img
            src={communityIcon}
            alt=""
            width={20}
            height={20}
            style={
              this.state.selected === "community"
                ? webStyle.svgImage(
                    "brightness(1) invert(0.8) sepia(1) saturate(5) hue-rotate(250deg)"
                  )
                : webStyle.svgImage("brightness(0) invert(1)")
            }
          />
          <Typography
            style={
              this.state.selected === "community"
                ? webStyle.text("#5E2A95", 600)
                : webStyle.text("white")
            }
          >
            Community
          </Typography>
        </Box>
      </Box>
    </>
    );
  }
}

const webStyle = {
  text: (color: string, fontWeight: number = 400) => ({
    color,
    fontSize: 14,
    fontWeight,
    alignSelf: "center",
    cursor: "default",
  }),
  box: (backgroundColor: string) => ({
    display: "flex",
    backgroundColor,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    height: 60,
    margin: "30 0 30 0",
    justifyContent: "start",
    paddingLeft: 50,
    alignItems: "center",
    flexDirection: "row",
  }),
  svgImage: (color: string) => ({
    marginRight: 10,
    filter: color,
  }),
  modalContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust opacity as needed
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: { 
    height: 300, 
    width: 350, 
    backgroundColor: "white", 
    borderRadius: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
};

export default Sidebar;
