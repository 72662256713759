import React from "react";
import DashboardController from "./DashboardController";
import { Accordion, AccordionDetails, AccordionSummary, Box, BoxProps, CircularProgress, Divider, Grid, Typography, styled } from "@material-ui/core";
import SideNavbar from "../../../components/src/SideNavbar.web";
import Sidebar from "../../../components/src/Sidebar.web";
import { designPattern, designPattern2, imagePicture } from "./assets";
import CircularProgressWithLabel from "../../../components/src/CircularProgressWithLabel.web";
import AppAccordion from "../../../components/src/AppAccordion.web";
import { EventNote } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Milestone, progressSide, sideImage } from "./assets";

export default class Dashboard extends DashboardController {
  render() {
    const BoxView = Box as React.FC<BoxProps>
    return (
      <>
        <SideNavbar
          navigation={this.props.navigation}
          id={this.props.id}
          showStudy={this.state.showStudy}
          showButtonChats
          showButtonNotification
        />

        <Box style={{ display: "flex", flexDirection: "row", flexGrow: 1}}>
          <Sidebar navigation={this.props.navigation} id={this.props.id} />

          <ModalStyle>
            <Grid xs={10} item >
              <BoxView className="subGrid">
                <Typography className="mainPatients">Dashboard</Typography>
                <Typography className="subText">Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium obcaecati architecto illum dicta repellat voluptates consequuntur quisquam nemo vel quo pariatur quaerat, dolore, voluptatum asperiores! Ex molestias velit voluptate maxime!</Typography>
                <Typography className="currentMilestone">Current Milestone</Typography>
                <Divider className="divider" />
                <BoxView className="listItemBoxWrap">
                  <BoxView className="listItemone" style={{ position: "relative" }}>
                    <BoxView className="listbox" style={{ position: "relative", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img src={progressSide} style={{ position: "absolute", right: "0", top: "0" }} />
                      <Box position="relative" style={{ left: "-20", top: "-35" }}>
                        <CircularProgress
                          variant="determinate"
                          value={100}
                          size={150}
                          thickness={4}
                          style={{ color: 'rgba(0,0,0,0.21', position: 'absolute',  }}
                        />
                        <CircularProgress
                          variant="determinate"
                          value={this.callPercentage()}
                          size={150}
                          thickness={4}
                          style={{ color: 'white', zIndex: 1, strokeLinecap: 'round' }}
                        />
                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          zIndex={3}
                        >
                          <Typography variant="caption" style={{ color: "white", fontFamily: "Jost", fontWeight: 700, fontSize: "30px" }}>
                          {this.callPercentage()}%
                          </Typography>
                        </Box>
                      </Box>
                    </BoxView>

                  </BoxView>
                  <BoxView className="listItemtwo">
                    <BoxView className="listbox">
                      <Typography className="total">{this.state.total_Milestones}</Typography>
                      <BoxView style={{ display: "flex", gap: "40px" }}>
                        <BoxView >
                          <Typography className="totalDashbord">Total</Typography>
                          <Typography className="milestone">Milestone</Typography>
                        </BoxView>
                        <BoxView>
                          <img src={Milestone} height={40} width={40} />
                        </BoxView>
                      </BoxView>
                    </BoxView>
                  </BoxView>

                  <BoxView className="listItemtwo">
                    <BoxView className="listbox">
                      <Typography className="total">{this.state.current_Milestones}</Typography>
                      <BoxView style={{ display: "flex", gap: "40px" }}>
                        <BoxView >
                          <Typography className="totalDashbord">Current</Typography>
                          <Typography className="milestone">Milestone</Typography>
                        </BoxView>
                        <BoxView>
                          <img src={Milestone} height={40} width={40} />
                        </BoxView>
                      </BoxView>
                    </BoxView>
                  </BoxView>
                </BoxView>

                <Typography className="MilestoneDetails">Milestone Details</Typography>
                <div>
                  {this.state.milestones?.map((milestone: any) => (
                    <Accordion id="MilestonesAccordion" key={milestone.id} className="accordion" defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="Milestone1">{milestone.attributes ? milestone.attributes.name : ""}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography dangerouslySetInnerHTML={{ __html: milestone.attributes?.milestone_description }} />
                      </AccordionDetails>
                      <Divider style={{ margin: "0px 20px" }} />
                      <AccordionDetails>
                        <BoxView className="accordionDetails">
                          <BoxView className="subDetails">
                            <BoxView className="boxDetails">
                              <Typography className="accordionDetailes" style={{ marginRight: "68px" }}>
                                Points :
                              </Typography>
                              <Typography className="accordionSubDetailes" >
                                15 Points
                              </Typography>
                            </BoxView>
                            <BoxView className="boxDetails">
                              <Typography className="accordionDetailes">
                                Duration:
                              </Typography>
                              <Typography className="accordionSubDetailes">
                                {this.calculateDuration(milestone.attributes?.start_date, milestone.attributes?.end_date)}
                              </Typography>
                            </BoxView>
                          </BoxView>
                          <BoxView className="accordionDetailesTwo">
                            <BoxView className="boxDetails">
                              <Typography className="accordionDetailes">
                                Start Date :
                              </Typography>
                              <Typography className="accordionSubDetailes">
                                <EventNote style={{ marginRight: "10px" }} /> {this.formatDate(milestone.attributes?.start_date)}
                              </Typography>
                            </BoxView>
                            <BoxView className="boxDetails">
                              <Typography className="accordionDetailes">
                                End Date
                              </Typography>
                              <Typography className="accordionSubDetailes">
                                <EventNote style={{ marginRight: "10px" }} />  {this.formatDate(milestone.attributes?.end_date)}
                              </Typography>
                            </BoxView>
                          </BoxView>
                        </BoxView>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                </div>
                <Typography className="MilestoneDetails">{this.state.studyName}</Typography>
                <Divider className="divider" />
                <BoxView className="vaccination">
                  <BoxView
                    className="boxDetails">
                    <Typography className="studyNumber">
                      Study Number:
                    </Typography>
                    <Typography className="accordionSubDetailes">
                      {this.state.studyNumber}
                    </Typography>
                  </BoxView>
                  <BoxView className="boxDetails">
                    <Typography className="accordionDetailes">
                      Total Milestone:
                    </Typography>
                    <Typography className="accordionSubDetailes">
                      {this.formatNumber(this.state.milestones?.length)}
                    </Typography>
                  </BoxView>
                  <BoxView className="boxDetails">
                    <Typography className="accordionDetailes">
                      Duration:
                    </Typography>
                    <Typography className="accordionSubDetailes">
                      {this.calculateTotalDuration(this.state.milestones)}
                    </Typography>
                  </BoxView>
                </BoxView>
                <BoxView className="listItemBoxWrap">
  <BoxView>
    <Accordion id="StudyDescriptionAccordion" className="accordion" defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className="detailOfTrail">Detail of {this.state.studyName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <BoxView className="listboxTwo">
          <img src={sideImage} width={153} />
          <BoxView>
            <Typography className="detailOfTrailText">
              <div dangerouslySetInnerHTML={{ __html: this.state.studyDescription }} />
            </Typography>
          </BoxView>
        </BoxView>
      </AccordionDetails>
      <Typography className="MilestoneDetails">Site Details</Typography>
                <div>
                  {this.state.sites?.map((site:any, index:any) => (
                    <Accordion key={site.id} className="accordion" id="sitesAccordion" defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                      >
                        <Typography className="site1">Site {index + 1}: {site.attributes?.site_name}</Typography>
                      </AccordionSummary>
                      <Divider style={{ margin: "0px 20px" }} />
                      <AccordionDetails>
                        <BoxView className="accordionDetails">
                          <BoxView className="subDetails">
                            <BoxView className="boxDetails">
                              <Typography className="accordionDetailes">
                                Site ID:
                              </Typography>
                              <Typography className="accordionSubDetailes">
                                {site?.id}
                              </Typography>
                            </BoxView>
                            <BoxView className="boxDetails">
                              <Typography className="accordionDetailes">
                                Site Coordinator:
                              </Typography>
                              <Typography className="accordionSubDetailes">
                                {site.attributes?.site_name}
                              </Typography>
                            </BoxView>
                            <BoxView className="boxDetails">
                              <Typography className="accordionDetailes">
                                Site Country
                              </Typography>
                              <Typography className="accordionSubDetailes">
                                {site.attributes?.site_country}
                              </Typography>
                            </BoxView>
                          </BoxView>
                          <BoxView className="accordionDetailesTwo">
                            <BoxView className="boxDetails">
                              <Typography className="accordionDetailes">
                                Site Address
                              </Typography>
                              <Typography className="accordionSubDetailes">
                                {site.attributes?.site_address}
                              </Typography>
                            </BoxView>
                          </BoxView>
                        </BoxView>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
    </Accordion>
  </BoxView>
</BoxView>
              </BoxView>
            </Grid>
          </ModalStyle>
        </Box>
      </>
    );
  }
}

const ModalStyle = styled(Box)({

  "& .mainPatients": {
    color: "#1F3650",
    fontFamily: "Jost",
    fontSize: "40px",
    fontWeight: 700,
  },
  "& .subGrid": {
    margin: "40px",
    width:'100%'
  },
  "& .subText": {
    color: "#414141",
    fontFamily: "Jost",
    fontSize: "14px",
  },
  "& .listItemone": {
    backgroundColor: "#A046FB",
    marginTop: "30px",
    width: "440px",
    height: "219px",
    borderRadius: "8px",
    color: "#fff"
  },
  "& .listbox": {
    padding: "40px 25px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  "& .total": {
    color: "#4A4949",
    fontFamily: "Jost",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  "& .totalDashbord": {
    color: "#434343",
    fontFamily: "Jost",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px"
  },
  "& .listItemBoxWrap": {
    display: "flex",
  },
  "& .currentMilestone": {
    color: "#292929",
    fontFamily: "Jost",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "34px",
    marginTop: 30
  },
  "& .divider": {
    marginTop: 15,
    maxWidth: "100%"
  },
  "& .listitem": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .listItemtwo": {
    backgroundColor: "#F0F0F0",
    marginTop: "30px",
    marginLeft: "25px",
    width: "206px",
    height: "219px",
    borderRadius: "8px",
    color: "#1F3650"
  },
  "& .listItemthree": {
    backgroundColor: "#F0F0F0",
    marginTop: "30px",
    marginLeft: "25px",
    width: "206px",
    height: "219px",
    borderRadius: "8px",
    color: "#1F3650",
  },
  "& .milestone": {
    color: "#434343",
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px"
  },
  "& .MilestoneDetails": {
    color: "#292929",
    fontFamily: "Jost",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "34px",
    marginTop: 30
  },
  "& .Milestone1": {
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26px"
  },
  "& .accordionDetailes": {
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26px",
    marginRight: "20px"
  },
  "& .accordionSubDetailes": {
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .accordion": {
    marginTop: "16px",
    backgroundColor: "#f0f0f0",
    boxShadow: "none",
    borderRadius: "8px",
    maxWidth: "900px",
  },
  "& .accordionDetails": {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginTop: "15px"
  },
  "& .subDetails": {
    display: "flex",
    gap: "150px"
  },
  "& .boxDetails": {
    display: "flex",
    flexDirection: "column",
    gap: "5px"
  },
  "& .accordionDetailesTwo": {
    display: "flex",
    gap: "120px"
  },
  "& .vaccination": {
    display: "flex",
    gap: "125px",
    marginTop: "30px"
  },
  "& .studyNumber": {
    color: "#292929",
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26px"
  },
  "& .listboxTwo": {
    padding: "25px",
    display: "flex",
    gap: "50px"
  },
  "& .detailOfTrail": {
    color: "#292929",
    fontFamily: "Jost",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "34px",
    marginBottom: "12px"
  },
  "& .detailOfTrailText": {
    color: "#1F0603",
    fontFamily: "Jost",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "30px",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
  "& .site1": {
    color: "#1F3650",
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26px"
  }
})