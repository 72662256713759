import { Avatar, styled } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import "./UserChat.css"

interface IProps {
    user: any,
    isShowTick?: number;
    createDialog?: any;
    getMessagesList?: any[];
    QB?: any;
    testId?: string;
    isSelectedUser?: boolean;
    isEmailSelected?: string;
    clickedSelectedUser?: string;
    emailData?: string;
    userIndex?: number;
    selectedQuickBloxId?: string;
}

export const UserChat: React.FC<IProps> = (props) => {
    const quickBloxId = localStorage.getItem("quickBloxId");

    const { user, isShowTick, createDialog, getMessagesList, testId, isEmailSelected, selectedQuickBloxId, isSelectedUser} = props

    const [unreadMsgCount, setUnreadMsgCount] = useState<number>(0);
    const userBgRef = useRef<any>(null)
    useEffect(() => {
        setUnreadMsgCount(unreadMsgCount + user?.unread_messages_count)
        if (userBgRef?.current || user?.occupants_ids?.includes(selectedQuickBloxId) || user?.occupants_ids?.includes(Number(quickBloxId))) {
            createDialog(user, user?._id);
            const activeUser = document.querySelector('.user-active')
            activeUser?.classList.remove("user-active")
            if (userBgRef.current.id || user?.occupants_ids?.includes(selectedQuickBloxId) || user?.occupants_ids?.includes(Number(quickBloxId))) {
                userBgRef.current.classList.add("user-active")
            }else{
                userBgRef.current.classList.remove("user-active")
            }
        }
    }, [isEmailSelected, user?.name, user?.unread_messages_count,user?.occupants_ids?.includes(selectedQuickBloxId)])

    getMessagesList?.forEach((val: any) => {
        if (val?.recipient_id === user?.user?.id) {
            const formatTime = (dateString: string) => {
                const date = new Date(dateString);
                const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

                const startTime = new Date(date);
                startTime.setHours(10, 0, 0, 0);
                const endTime = new Date(date);
                endTime.setHours(13, 0, 0, 0);

                const formattedTime = (date >= startTime && date < endTime) ? date.toLocaleTimeString('en-US', options) : "01:00 PM";

                return formattedTime;
            };
        }
    })

    const handleSelectedUser = (e: any, userId: string) => {
        createDialog(user, userId);
        if (userBgRef?.current) {
            const activeUser = document.querySelector('.user-active')
            activeUser?.classList.remove("user-active")
            if (userBgRef.current.id || user?.occupants_ids?.includes(Number(quickBloxId))) {
                userBgRef.current.classList.add("user-active")
            }else{
                
                userBgRef.current.classList.remove("user-active")
            }
        }
    }

    useEffect(()=>{
        if(user?.occupants_ids?.includes(selectedQuickBloxId) && quickBloxId){
            createDialog(user, user?._id);
        }

        return ()=>{
            createDialog({}, '');
        }
    },[])

    return (
        <>
            {isShowTick !== 0 ? <UserContainer id={user?._id} className='user-bg ' ref={userBgRef} data-testid={testId} style={{ display: 'flex', padding: 16, justifyContent: 'center', alignItems: 'center' }} onClick={(e) => { handleSelectedUser(e, user?._id) }}>
                <Avatar style={{ marginRight: 8 }} alt="Remy Sharp" src={user.avatar} />
                <div style={{ flex: 1, }}>
                    <div style={{ display: 'flex' }}>
                        <Username style={{ flex: 1, marginBottom: 8 }}>{user?.name}</Username>
                        <ReceivedAt>{user.receivedAt}</ReceivedAt>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <LastMessage style={{ flex: 1 }}>{user.lastMessage}</LastMessage>
                        {isShowTick ? <UnreadMessageCounterContainer>
                            <UnreadMessageCounter>{unreadMsgCount}</UnreadMessageCounter>
                        </UnreadMessageCounterContainer> : null}
                    </div>
                </div>
            </UserContainer> : <UserContainer id={user?._id} className='user-bg ' ref={userBgRef} data-testid={testId} style={{ display: 'flex', padding: 16, justifyContent: 'center', alignItems: 'center', }} onClick={(e) => { handleSelectedUser(e, user?._id) }}>
                <Avatar style={{ marginRight: 8 }} alt="Remy Sharp" src={user.avatar} />
                <div style={{ flex: 1, }}>
                    <div style={{ display: 'flex' }}>
                        <Username style={{ flex: 1, marginBottom: 8 }}>{user?.name}</Username>
                        <ReceivedAt>{user.receivedAt}</ReceivedAt>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <LastMessage style={{ flex: 1 }}>{user.lastMessage}</LastMessage>
                    </div>
                </div>
            </UserContainer>}
        </>
    )
}

export const UserContainer = styled(`div`)({
    transition: 'background-color 0.3s',
    cursor: 'pointer',
    borderRadius: '8px',
    "&:hover": {
        backgroundColor: 'lightgray !important',
    },
})

export const Username = styled(`span`)({
    fontWeight: 600,
    fontFamily: "Inter",
    color: 'black',
})

export const ReceivedAt = styled(`span`)({
    fontSize: 14,
    fontFamily: "Inter",
})



export const UnreadMessageCounterContainer = styled(`div`)({
    width: 20,
    height: 20,
    borderRadius: 10,
    background: "#A046FB",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
})

export const UnreadMessageCounter = styled(`span`)({
    fontSize: 13,
    color: 'white'
})

export const LastMessage = styled(`span`)({
    fontSize: 13,
    color: 'rgba(192, 192, 192, 0.7)'
})


