import moment from "moment";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  available_date: any;
  start_time: any;
  end_time: any;
  id: any;
  token: string;
  appointmentsList: any;
  isRefreshing: boolean;
  milestones: any;
  value: any;
  tabValue: any;
  prevPage: any;
  upcomingPage: any;
  currentPage: any;
  open: boolean;
  selectedTimeZone: any;
  dataArray: any;
  checked: boolean;
  openTime: boolean;
  editorState: any;
  Reccurance: any;
  selectedDate: any;
  isDatePickerOpen: boolean;
  selectedTime: any;
  durations: any;
  minutes: any;
  selectedOption: any;
  openMilestone: boolean;
  openDuration: boolean;
  openMinute: boolean;
  setDuration: any;
  setMinute: any;
  endDate: any;
  title: any;
  address1: any;
  address2: any;
  address3: any;
  openMilestoneWithDrawer: boolean;
  selectedOption1: any;
  selectedDays: any;
  selectedStudy: any;
  openOnthe: boolean;
  onthe: any;
  onthevalue: any;
  openDay: boolean;
  dayOn: any;
  dayonvalue: any;
  dailyValue: any;
  WeeklyValue: any;
  MonthlyValue: any;
  on_day: any;
  yearlyDate: any;
  yearlyDayFormat: any;
  patientList: any;
  selectedRadioYear: any;
  selectedRadioMonth: any;
  weekdays: any;
  addPatientList: any;
  milestonesID: any;
  milestonesID1: any;
  selectedDayLong: any;
  showDate: any;
  tabs: { label: string, value: string | number }[];
  selectedTab: string | number;
  upcomming: any;
  page: number;
  previous: any;
  openPages: boolean;
  pageCount: any;
  setPages: any;
  itemsPerPage: number;
  openMenu: boolean;
  openMenuIndex: any;
  showDetails: boolean;
  SelectedAppointment: any;
  myCalenderFlag: boolean;
  ccDate: any;
  contentArray: any;
  add_Schedule_Title: any;
  editFlag: boolean;
  scheduleID: any;
  patientIdsArray: any;
  deleteFlag: boolean;
  editableTitle: boolean;
  editableDesc: boolean;
  errorTitle: any;
  errorDesc: any;
  erroradd1: any;
  erroradd2: any;
  erroradd3: any;
  customArray: any;
  customRepeatEveryArray: any;
  customRepeatEveryValue: any;
  customRepeatEveryFlag: boolean;
  customValue: any;
  customFlag: boolean;
  milstoneError: boolean;
  milstoneErrorText: any;
  milestoneNew: any;
  patientError: boolean;
  patientErrorText: any;
  startDateError: boolean;
  startDateErrorText: any;
  startTimeError: boolean;
  startTimeErrorText: any;
  durationError: boolean;
  durationErrorText: any;
  minuteError: boolean;
  minuteErrorText: any;
  addressError: boolean;
  addressErrorText: any;
  endDateErrorText: any;
  endDateError: boolean;
  openAttendence: boolean;
  attendencePatientList: any;
  selectCoordinatorResp: any;
  coordinatorResp: boolean;
  attendenceID: any;
  scheduleOnCalender: any;
  calenderMonth: any;
  calenderYear: any;
  selectedEvent: any;
  allEvents: any;
  disableFlag: boolean;
  patientResponses: { [key: string]: string };
  coordinatorResponses: { [key: string]: string };
  displayedContent:any
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentmanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addAppointmentApiCallId: any;
  getAppointmentsListApiCallId: any;
  deleteAllAppointmentsApiCallId: any;
  getAllMilstoneCallId: any;
  getAllMilstoneCallIdNew: any;
  addScheduleApiCallId: any;
  getAllScheduleApiCallID: any;
  getScheduleDeleteApiCallID: any;
  editScheduleApiCallID: any;
  showScheduleAttendenceCallID: any;
  updateAttendenceCallID: any;
  getCalenderCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),

      // Customizable Area End
    ];

    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: new Date(),
      end_time: endTime,
      available_date: moment(new Date()).format("DD/MM/YY"),
      appointmentsList: [],
      token: "",
      isRefreshing: false,
      milestones: [],
      value: "",
      tabValue: 0,
      prevPage: 1,
      upcomingPage: 1,
      currentPage: 1,
      open: false,
      selectedTimeZone: '',
      dataArray: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
      checked: false,
      openTime: false,
      editorState: "",
      Reccurance: ['Daily', 'Weekly', 'Monthly', 'Yearly', 'Custom'],
      selectedDate: null,
      isDatePickerOpen: false,
      selectedTime: null,
      durations: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17",
        "18", "19", "20", "21", "22", "23"],
      minutes: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17",
        "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35",
        "37", "38", "39", "40", "41", "42",
        "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57",
        "58", "59"],
      selectedOption: "",
      openMilestone: false,
      openDuration: false,
      openMinute: false,
      setDuration: "",
      setMinute: "",
      endDate: null,
      title: "",
      address1: "",
      address2: "",
      address3: "",
      openMilestoneWithDrawer: false,
      selectedOption1: "",
      selectedDays: "Mon",
      selectedStudy: "",
      openOnthe: false,
      onthe: ["First", "Second", "Third", "Fourth", "Fifth"],
      onthevalue: "",
      openDay: false,
      dayOn: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thusday", "Friday", "Saturday"],
      dayonvalue: "",
      dailyValue: "",
      WeeklyValue: "",
      MonthlyValue: "",
      on_day: "",
      yearlyDate: "",
      yearlyDayFormat: "",
      patientList: [],
      selectedRadioYear: "",
      selectedRadioMonth: "",
      weekdays: [{ short: 'Sun', long: 'Sunday' }, { short: 'Mon', long: 'Monday' }, { short: 'Tue', long: 'Tuesday' },
      { short: 'Wed', long: 'Wednesday' }, { short: 'Thu', long: 'Thusday' },
      { short: 'Fri', long: 'Friday' }, { short: 'Sat', long: 'Saturday' }],
      addPatientList: [],
      milestonesID: "",
      milestonesID1: "",
      selectedDayLong: "Monday",
      showDate: "",
      tabs: [
        { label: "previous", value: "Previous" },
        { label: "upcoming", value: "Upcoming" }
      ],
      selectedTab: "Previous",
      upcomming: [],
      page: 1,
      previous: [],
      openPages: false,
      pageCount: ["10", "15", "20", "25"],
      setPages: "",
      itemsPerPage: 10,
      openMenu: false,
      openMenuIndex: "",
      showDetails: false,
      SelectedAppointment: "",
      myCalenderFlag: false,
      ccDate: new Date(),
      contentArray: [],
      add_Schedule_Title: "Add Schedule",
      editFlag: false,
      scheduleID: "",
      patientIdsArray: [],
      deleteFlag: false,
      editableTitle: true,
      editableDesc: true,
      errorTitle: "",
      errorDesc: "",
      erroradd1: "",
      erroradd2: "",
      erroradd3: "",
      customArray: ["Day", "Week", "Month", "Year"],
      customRepeatEveryArray: ["1", "2", "3", "4", "5"],
      customRepeatEveryFlag: false,
      customRepeatEveryValue: "",
      customValue: "",
      customFlag: false,
      milstoneError: false,
      milstoneErrorText: "",
      milestoneNew: [],
      patientError: false,
      patientErrorText: "",
      startDateError: false,
      startDateErrorText: "",
      startTimeError: false,
      startTimeErrorText: "",
      durationError: false,
      durationErrorText: "",
      minuteError: false,
      minuteErrorText: "",
      addressError: false,
      addressErrorText: "",
      endDateErrorText: "",
      endDateError: false,
      openAttendence: false,
      attendencePatientList: [],
      selectCoordinatorResp: "Not Attended ",
      coordinatorResp: false,
      attendenceID: "",
      scheduleOnCalender: [],
      calenderMonth: "",
      calenderYear: "",
      selectedEvent: null,
      allEvents: [],
      disableFlag: false,
      patientResponses: {},
      coordinatorResponses: {},
      displayedContent:[]
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getAllMileStones()
    this.getAllMileStonesNew()
    this.getcurrentDay()
    this.getCurrentDayMonthFormate()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.getAllMilstoneCallId && responseJson) {
          this.callResponceMilestone(responseJson)
        } else if (apiRequestCallId === this.addScheduleApiCallId && responseJson) {
          this.callResponceAddSchedule(responseJson)
        } else if (apiRequestCallId === this.getAllScheduleApiCallID && responseJson) {
          this.callResponceAllSchedule(responseJson);
        } else if (apiRequestCallId === this.getScheduleDeleteApiCallID && responseJson) {
          this.callResponceDelete(responseJson)
        } else {
          this.callOtherApis(apiRequestCallId, responseJson)
        }
      }

    }
    // Customizable Area End
  };

  // Customizable Area Start
  callSetResponce = (row: any) => {
    this.setState({
      coordinatorResp: !this.state.coordinatorResp,
      attendenceID: row.id
    })
  }
  callAttendence = () => {
    this.setState({
      openAttendence: !this.state.openAttendence,
    })
    this.callAppointmentAttendence();

  }
  handleRadioChange = (value: any, row: any) => {
    this.setState({ coordinatorResp: false });
  
    if (this.state.selectedTab === "Previous") {
      this.setState({ selectCoordinatorResp: value, coordinatorResp: false });
      this.callUpdateCoordinatorResponce(value, row);
    } else {
      alert("Upcomming schedule can update Attendence");
    }
  
    this.setState({
      patientResponses: { ...this.state.patientResponses, [row.id]: value },
      coordinatorResponses: { ...this.state.coordinatorResponses, [row.id]: value },
    });
  };
  callUpdateCoordinatorResponce = (value: any, row: any) => {
    let count = 0;
    if (value === "Attended") {
      count = 0
    } else if (value === "Not Attended") {
      count = 1
    }
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": token
    };
    const bodyis = {
      "schedule_patient": {
        "account_id": row.id,
        "coordinator_response": count
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateshowScheduleApiEndPoint + this.state.SelectedAppointment.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyis)
    );
    this.updateAttendenceCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  calculateYesEResponce = (array: any) => {
    let count = 0;
    if (array) {
      count = array.filter((response: { invite_response: string; }) => response.invite_response === "yes").length;
    }
    return count
  }
  calculateNoResponce = (array: any) => {
    let count = 0;
    if (array) {
      count = array.filter((response: { invite_response: null; }) => response.invite_response === "no").length
    }
    return count
  }
  callAppointmentAttendence = () => {

    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": token
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showScheduleAttendenceCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showScheduleApiEndPoint + this.state.SelectedAppointment.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  callOtherApis = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.editScheduleApiCallID && responseJson) {
      this.callResponceEditApi(responseJson)
    } else if (apiRequestCallId === this.getAllMilstoneCallIdNew && responseJson) {
      this.callResponceofAllMilestone(responseJson)
    } else if (apiRequestCallId === this.showScheduleAttendenceCallID) {
      if (responseJson) {
        if (responseJson.data.attributes.patient_ids) {
          this.setState({ attendencePatientList: responseJson.data.attributes.patient_ids })
        }
      }
    } else if (apiRequestCallId === this.updateAttendenceCallID) {
      this.callAppointmentAttendence()
    } else if (apiRequestCallId === this.getCalenderCallID) {
      if (responseJson) {
        this.callResponceCalender(responseJson)
      }


    }
  }
  callFormateNewDate = (date1: any, time1: any) => {
    const dateTimeString = `${date1} ${time1}`;
    const dateTimeMoment = moment(dateTimeString, 'YYYY-MM-DD hh:mm A');

    const year = dateTimeMoment.year();
    const month = dateTimeMoment.month();
    const day = dateTimeMoment.date();
    const hours = dateTimeMoment.hours();
    const minutes = dateTimeMoment.minutes();
    const seconds = dateTimeMoment.seconds();


    const newDateObject = new Date(year, month, day, hours, minutes, seconds);
    return (newDateObject)
  }
  callResponceCalender = (responseJson: any) => {
    const events = responseJson.map((event: any) => {
      if (event.schedule.data) {
        return {
          id: event.schedule.data?.id,
          title: event.schedule.data?.attributes.title,
          start: this.callFormateNewDate(event.schedule.data?.attributes.start_date, event.schedule.data?.attributes.start_time),
          end: this.callFormateNewDate(event.schedule.data?.attributes.end_date, event.schedule.data?.attributes.end_time),
          desc: event.schedule.data?.attributes.description,
          allDay: false,
          time_zone: event.schedule.data?.attributes.time_zone,
          ssdate: event.schedule.data?.attributes.start_date,
          sstime: event.schedule.data?.attributes.start_time,
          eedate: event.schedule.data?.attributes.end_date,
          eetime: event.schedule.data?.attributes.end_time,
          duration_hour: event.schedule.data?.attributes.duration_hour,
          minute: event.schedule.data?.attributes.minute,
          address: event.schedule.data?.attributes.address,
          patient_ids: event.schedule.data?.attributes.patient_ids,
        };
      } else {
        return null; // Handle null data or other conditions as needed
      }
    }).filter((event: any) => event !== null); // Remove any null items

    this.setState({ scheduleOnCalender: events, allEvents: responseJson })
  }
  callResponceofAllMilestone = (responseJson: any) => {
    this.setState({
      milestoneNew: responseJson,
      selectedOption1: responseJson[responseJson.length - 1].name
    })
  }
  handleNavigate = (date: any) => {
    const month = moment(date).format('MMMM');
    const year = moment(date).format('YYYY');
    this.setState({ calenderMonth: month, calenderYear: year }, () => { this.callGetCalenderSchedule() })

  };
  callResponceEditApi = (responseJson: any) => {
    if (responseJson.errors) {
      alert(JSON.stringify(responseJson.errors[0]))
    } else {
      alert(responseJson.message)
      this.handleDrawerCloseAdd();
      this.getAllMileStones();
    }
  }
  callResponceDelete = (responseJson: any) => {
    alert(responseJson.message);
    this.getAllMileStones();
  }
  callResponceAllSchedule = (responseJson: any) => {
    this.setState({
      previous: responseJson.previous_schedules.data,
      upcomming: responseJson.upcoming_schedules.data
    })
  }
  handleEventClick = (event: any) => {
    this.setState({ selectedEvent: event })
  };

  callResponceAddSchedule = (responseJson: any) => {
    if (responseJson.errors) {
      if (responseJson.errors.hasOwnProperty("milestone_management")) {
        this.setState({ milstoneError: true, milstoneErrorText: "Milestone must exist" })
      }
      if (responseJson.errors.hasOwnProperty("title")) {
        this.setState({ errorTitle: "Title must exist" })
      }
      if (responseJson.errors.hasOwnProperty("description")) {
        this.setState({ errorDesc: "Description must exist" })
      }
      if (responseJson.errors.hasOwnProperty("patient_ids")) {
        this.setState({ patientErrorText: "Patient can't be blank", patientError: true })
      }
      if (responseJson.errors.hasOwnProperty("start_date")) {
        this.setState({ startDateErrorText: responseJson.errors.start_date, startDateError: true })
      }
      if (responseJson.errors.hasOwnProperty("start_time")) {
        this.setState({ startTimeErrorText: "Start Time can't be blank", startTimeError: true })
      }

      this.callErrorResponce(responseJson)

    } else {
      this.callSuccesResponce()
    }
  }
  callErrorResponce = (responseJson: any) => {
    if (responseJson.errors.hasOwnProperty("duration_hour")) {
      this.setState({ durationErrorText: "Duration Time can't be blank", durationError: true })
    }
    if (responseJson.errors.hasOwnProperty("minute")) {
      this.setState({ minuteErrorText: "Minute can't be blank", minuteError: true })
    }
    if (responseJson.errors.hasOwnProperty("address")) {
      this.setState({ addressErrorText: "Address can't be blank", addressError: true })
    }
    if (responseJson.errors.hasOwnProperty("end_date")) {
      this.setState({
        endDateErrorText: responseJson.errors.end_date
        , endDateError: true
      })
    }
    if (responseJson.errors.hasOwnProperty("base")) {
      alert(responseJson.errors.base)
    }
  }
  callSuccesResponce = () => {
    alert("Schedule Added Successfully")
    this.handleDrawerCloseAdd();
    this.getAllMileStones();
  }
  callResponceMilestone = (responseJson: any) => {
    this.setState({
      milestones: responseJson.milestones,
      patientList: responseJson.patients,
    })
    this.getAllAppointments(responseJson?.milestones[0]?.id)
  }
  formattedDateStart = (date: any) => {
    const date1 = new Date(date);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' };
    return date1.toLocaleDateString('en-US', options);
  }


  handleSelectTab = (value: string | number) => {
    this.setState({ selectedTab: value });
  }

  handleOptionChangeRadioYear = (event: any) => {
    this.setState({ selectedRadioYear: event.target.value });
  };
  handleOptionChangeRadioMonth = (event: any) => {
    this.setState({ selectedRadioMonth: event.target.value });
  };
  ShowAppointmentDetails = (content: any) => {

    this.setState({
      showDetails: true,
      SelectedAppointment: content
    })

  }
  getInitials(name: any) {
    if (name) {
      const words = name.split(' ');

      const initials = words.map((word: string) => word.charAt(0).toUpperCase());

      return initials.join('');
    }
  }
  closeAppointmeentDetails = () => {
    this.setState({ showDetails: !this.state.showDetails })
  }
  getCurrentDayMonthFormate() {
    const getNthDayOfMonth = (year: number, month: number, dayOfWeek: number, occurrence: number) => {
      const date = new Date(year, month, 1);
      let count1 = 0;
      while (date.getDay() !== dayOfWeek || ++count1 < occurrence) {
        date.setDate(date.getDate() + 1);
      }
      return date;
    };


    const currentDate = new Date();

    const dayOfWeek = currentDate.getDay();
    const dayOfMonth = currentDate.getDate();
    const positionInMonth = Math.floor((dayOfMonth - 1) / 7) + 1;

    const nthDayOfMonth = getNthDayOfMonth(currentDate.getFullYear(), currentDate.getMonth(), dayOfWeek, positionInMonth);



    const getPositionInMonthString = (positionInMonth1: any) => {
      const positions = ['first', 'second', 'third', 'fourth', 'fifth'];

      const index = positionInMonth1 - 1;

      if (index >= 0 && index < positions.length) {
        return positions[index];
      }

    };

    const formattedDate = `${getPositionInMonthString(positionInMonth)} ${nthDayOfMonth.toLocaleString('en-US', { weekday: 'long' })} of ${nthDayOfMonth.toLocaleString('en-US', { month: 'long' })}`;
    this.setState({ yearlyDayFormat: formattedDate })
  }
  setStateFlag = (flag: any, text: any) => {
    if (text === "Duration Hour") {
      this.setState({ openDuration: !flag })
    } else if (text === "Minute") {
      this.setState({ openMinute: !flag })
    } else if (text === "Third") {
      this.setState({ openOnthe: !flag })
    } else if (text === "Tuesday") {
      this.setState({ openDay: !flag })
    } else if (text === "page") {
      this.setState({ openPages: !flag })
    } else if (text === "1") {
      this.setState({ customRepeatEveryFlag: !flag })
    } else if (text === "custom") {
      this.setState({ customFlag: !flag })
    }
  }
  getcurrentDay() {
    const currentDate = new Date();
    const month = currentDate.toLocaleString('en-US', { month: 'long' });
    const date = currentDate.getDate();
    const formattedDate = `${month}  ${date}`;
    this.setState({ yearlyDate: formattedDate })
  }
  handleChangeTimeZone = (event: any) => {
    this.setState({ selectedTimeZone: event.target.value })
  }
  handleTimeChange = (time: any) => {
    this.setState({ selectedTime: time, startTimeError: false })

  };
  handleChangeMonthlyValue = (event: any) => {
    const inputValue = event.target.value;

    const integerNumber = parseInt(inputValue)
    const result = new Date(this.state.selectedDate);
    result.setMonth(result.getMonth() + (integerNumber - 1));

    if (/^\d*$/.test(inputValue)) {
      this.setState({ MonthlyValue: inputValue, endDate: result })
    }
  };
  handleChangeOnDayValue = (event: any) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      this.setState({ on_day: inputValue })
    }
  };
  handleChangeWeeklyValue = (event: any) => {
    const inputValue = event.target.value;

    const integerNumber = parseInt(inputValue)
    const result = new Date(this.state.selectedDate);
    result.setDate(result.getDate() + ((integerNumber - 1) * 7));

    if (/^\d*$/.test(inputValue)) {
      this.setState({
        WeeklyValue: inputValue, endDate: result
      })
    }
  };
  checkedBox = (event: any) => {

    if (this.state.selectedDate) {
      this.setState({ checked: event.target.checked })
    } else {
      alert("For Reccurring meeting Please select Start Date")

    }
  }


  handleChangeDailyValue = (event: any) => {
    const inputValue = event.target.value;

    const integerNumber = parseInt(inputValue)
    const result = new Date(this.state.selectedDate);
    result.setDate(result.getDate() + (integerNumber - 1));


    if (/^\d*$/.test(inputValue)) {
      this.setState({
        dailyValue: inputValue,
        endDate: result
      })
    }

  };
  openCalender = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const currentYear = currentDate.getFullYear();
    this.setState({
      myCalenderFlag: !this.state.myCalenderFlag,
      calenderMonth: currentMonth, calenderYear: currentYear
    }, () => { this.callGetCalenderSchedule() });

  };
  closeAttendence = () => {
    this.setState({ openAttendence: !this.state.openAttendence });

  }
  callGetCalenderSchedule = async () => {

    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": token
    };

    const studyId = await getStorageData("studyId")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCalenderCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.calenderApiEndPoint + this.state.calenderMonth + '&year=' + this.state.calenderYear + `&study_id=${studyId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  toggleDrawer = () => {
    this.setState({
      open: !this.state.open, editFlag: false,
      add_Schedule_Title: "Add Schedule"
    });
  };
  handleDrawerCloseAdd = () => {
    this.setState({
      open: false,
      title: '', selectedOption: '', selectedDate: null, selectedTime: null,
      editorState: '', address1: '', address2: '', address3: '',
      value: "", setDuration: "", setMinute: "", endDate: null,
      addPatientList: [], checked: false, milestonesID: "", milstoneError: false,
      errorTitle: "", errorDesc: "", patientError: false, startDateError: false, startTimeError: false,
      durationError: false, minuteError: false, addressError: false
    })
  }

  handleOptionClick = (option: any, drawerFlag: boolean) => {
    if (drawerFlag) {
      this.setState({ selectedOption1: option.name, openMilestoneWithDrawer: false, milestonesID1: option.id, milstoneError: false })
      this.getAllAppointments(option.id)
    } else {
      this.setState({
        selectedOption: option.name, openMilestone: false, milestonesID: option.id,
        milstoneError: false
      })
    }
  }

  handleOptionClickDay = (option: any) => {
    this.setState({ dayonvalue: option, openDay: false })
  }
  handleCustomRepeatEvery = (option: any) => {

    const integerNumber = parseInt(option)
    const result = new Date(this.state.selectedDate);

    if (this.state.customValue === "Day") {
      result.setDate(result.getDate() + (integerNumber - 1));
    } else if (this.state.customValue === "Month") {
      result.setMonth(result.getMonth() + (integerNumber - 1));
    } else if (this.state.customValue === "Week") {
      result.setDate(result.getDate() + ((integerNumber - 1) * 7));
    }


    this.setState({
      customRepeatEveryValue: option,
      customRepeatEveryFlag: false,
      endDate: result
    })



  }
  handleCustomValue = (option: any) => {
    const integerNumber = parseInt(this.state.customRepeatEveryValue)
    const result = new Date(this.state.selectedDate);
    if (option === "Day") {
      result.setDate(result.getDate() + (integerNumber - 1));
    } else if (option === "Month") {
      result.setMonth(result.getMonth() + (integerNumber - 1));
    } else if (option === "Week") {
      result.setDate(result.getDate() + ((integerNumber - 1) * 7));
    }
    this.setState({
      customValue: option,
      customFlag: false,
      endDate: result
    })
  }
  handleOptionClickonthe = (option: any) => {
    this.setState({ onthevalue: option, openOnthe: false })
  }
  handleOptionClickDuration = (option: any) => {
    this.setState({ setDuration: option, openDuration: false, durationError: false })
  }
  callDateChangeFormat = (dateString: any) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };
    return new Intl.DateTimeFormat('en-GB', options).format(date)
  }

  handleitemsPerPage = (option: any) => {
    this.setState({ setPages: option, itemsPerPage: option, openPages: false })
  }
  handleOptionClickMinute = (option: any) => {
    this.setState({ setMinute: option, openMinute: false, minuteError: false })
  }

  handleEditorChange = (text: any) => {
    if (text.length <= 100) {
      this.setState({ editorState: text, errorDesc: "" })
    } else {
      this.setState({ errorDesc: "Enter Description with Max 100 characters" })
    }

  };
  handleDateChange = (date: any) => {

    this.setState({ selectedDate: date }, () => this.hadleSelectReccurance(this.state.value));
    const date1 = new Date(date);
    const day = date1.getDate().toString().padStart(2, '0');
    const month = (date1.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date1.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    this.setState({ showDate: formattedDate, startDateError: false })

  };
  handleEndDateChange = (date: any) => {
    this.setState({ endDate: date, endDateError: false });
  };
  handleDayClick = (day: any) => {
    this.setState({
      selectedDays: day.short,
      selectedDayLong: day.long
    });
  };
  deleteItem = (item1: any) => {
    const updatedItems = this.state.addPatientList.filter((item: any) => item.id !== item1.id);
    this.setState({ addPatientList: updatedItems })

  }
  hadleSelectReccurance = (option: any) => {
    this.setState({
      value: option,
      endDate: null
    })

    const dateString = this.state.selectedDate;
    const date = new Date(dateString);
    const dayNameAbbreviated = date.toLocaleString('en-US', { weekday: 'short' });
    const dayNameAbbreviated1 = date.toLocaleString('en-US', { weekday: 'long' });



    const dayOfMonth1 = date.getDate();


    const getNthDayOfMonth = (year: number, month: number, dayOfWeek: number, occurrence: number) => {
      const date = new Date(year, month, 1);
      let count = 0;
      while (date.getDay() !== dayOfWeek || ++count < occurrence) {
        date.setDate(date.getDate() + 1);
      }
      return date;
    };


    const dayOfWeek = date.getDay();
    const dayOfMonth = date.getDate();
    const positionInMonth = Math.floor((dayOfMonth - 1) / 7) + 1;

    const getPositionInMonthStringNew = (positionInMonth: any) => {
      const positions = ['first', 'second', 'third', 'fourth', 'fifth'];

      const index = positionInMonth - 1;

      if (index >= 0 && index < positions.length) {
        return positions[index];
      }

    };


    const nthDayOfMonth = getNthDayOfMonth(date.getFullYear(), date.getMonth(), dayOfWeek, positionInMonth);

    const formattedDate = `${getPositionInMonthStringNew(positionInMonth)} ${nthDayOfMonth.toLocaleString('en-US', { weekday: 'long' })} of ${nthDayOfMonth.toLocaleString('en-US', { month: 'long' })}`;



    const month2 = date.toLocaleString('en-US', { month: 'long' });
    const date2 = date.getDate();
    const formattedDate1 = `${month2}  ${date2}`;

    if (option === "Weekly") {
      this.setState({ selectedDays: dayNameAbbreviated, selectedDayLong: dayNameAbbreviated1 })
    }

    if (option === "Monthly") {

      this.setState({
        on_day: dayOfMonth1,
        onthevalue: getPositionInMonthStringNew(positionInMonth),
        dayonvalue: nthDayOfMonth.toLocaleString('en-US', { weekday: 'long' })
      })
    }
    if (option === "Yearly") {


      this.setState({
        yearlyDate: formattedDate1,
        yearlyDayFormat: formattedDate,

      })
    }
    if (option === "Custom") {
      this.setState({
        selectedDays: dayNameAbbreviated, selectedDayLong: dayNameAbbreviated1,
        yearlyDate: formattedDate1,
        yearlyDayFormat: formattedDate,
        on_day: dayOfMonth1,
        onthevalue: getPositionInMonthStringNew(positionInMonth),
        dayonvalue: nthDayOfMonth.toLocaleString('en-US', { weekday: 'long' })
      })
    }
  }
  handleSelectNew = (option: any) => {
    this.setState({ selectedTimeZone: option })
   
  }
  handleFlag=(flag:boolean)=>{
if(flag){
  this.setState({openMilestone:false})
}
  }
  handleSelect = (option: any, newArray: any) => {
    this.setState({
      addPatientList: newArray, patientError: false
    })

  };
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  getData = (data: any) => {
    this.setState({ addPatientList: data })
  }
  getUpdatedData = (data: any) => {
    this.setState({ contentArray: data })
  }
  setIsOpen = (flag: any) => {
    this.setState({ openMilestone: flag, })
  }

  setIsOpen1 = (flag: any) => {
    this.setState({ openMilestoneWithDrawer: flag })
  }
  changeTitle = (event: any) => {

    if (event.target.value.length <= 25) { // Validate input length
      this.setState({
        title: event.target.value,
        errorTitle: ""
      })
    } else {
      this.setState({ errorTitle: "Enter Title with Max 25 characters" })
    }
  }
  changeAddress1 = (event: any) => {
    if (event.target.value.length <= 25) {
      this.setState({
        address1: event.target.value,
        erroradd1: "",
        addressError: false
      })
    } else {
      this.setState({ erroradd1: "Enter Address with Max 35 characters" })
    }
  }
  changeAddress2 = (event: any) => {
    if (event.target.value.length <= 25) {
      this.setState({
        address2: event.target.value,
        erroradd2: "",
        addressError: false
      })
    } else {
      this.setState({ erroradd2: "Enter Address with Max 35 characters" })
    }
  }
  changeAddress3 = (event: any) => {
    if (event.target.value.length <= 25) {
      this.setState({
        address3: event.target.value,
        erroradd3: "",
        addressError: false
      })
    } else {
      this.setState({ erroradd3: "Enter Address with Max 35 characters" })
    }
  }
  ShowMenu = (event: any, index: any) => {

    event.stopPropagation();
    this.setState({
      openMenu: !this.state.openMenu,
      showDetails: false,
      openMenuIndex: index
    })
  }
  addAppointment(): boolean {
    if (
      this.isStringNullOrBlank(this.state.available_date) ||
      this.isStringNullOrBlank(this.state.start_time) ||
      this.isStringNullOrBlank(this.state.end_time)
    ) {
    }

    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: this.state.token,
    };

    const attrs = {
      start_time: moment(this.state.start_time).format("HH:mm A"),
      end_time: moment(this.state.end_time).format("HH:mm A"),
      availability_date: moment(this.state.available_date, "DD/MM/YY").format(
        "YY/MM/DD"
      ),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAppointmentApiCallId = requestMessage.messageId;

    const httpBody = {
      ...attrs,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.appointmentAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAppointmentList = (token: any) => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const attrs = {
      service_provider_id: "1",
      availability_date: this.state.available_date,
    };

    this.getAppointmentsListApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.appointmentAPiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToAppointments = () => {
    this.props.navigation.navigate("Appointments");
  };
  // Customizable Area Start
  getAllMileStonesNew = () => {
    let parseData;
    let studyNameStore = localStorage.getItem("studyName")
    if (studyNameStore != null) {
      parseData = JSON.parse(studyNameStore)
    }


    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": token
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllMilstoneCallIdNew = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllMileStoneEndPointNew + parseData.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllMileStones = () => {
    let parseData;
    let studyNameStore = localStorage.getItem("studyName")
    if (studyNameStore != null) {
      parseData = JSON.parse(studyNameStore)
    }


    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": token
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllMilstoneCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllMileStoneEndPoint + parseData.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  callLog = () => {
    if (this.state.editFlag) {
      if (this.state.addPatientList && this.state.addPatientList.length > 0) {
        let arr = [];
        arr = this.state.addPatientList.map((item: { id: any; }) => item.id);
        this.setState({ patientIdsArray: arr }, () => { this.editScheduleApi() })

      } else {
        this.editScheduleApi()
      }
    } else {
      if (this.state.addPatientList && this.state.addPatientList.length > 0) {
        let arr = [];
        arr = this.state.addPatientList.map((item: { id: any; }) => item.id);
        this.setState({ patientIdsArray: arr }, () => { this.SubmitAddSchedule() })

      } else {
        this.SubmitAddSchedule()
      }

    }
  }
  SelectMethod = () => {

    if (this.state.editFlag) {
      this.setState({ add_Schedule_Title: "Edit Schedule", })
    } else {
      this.setState({ add_Schedule_Title: "Add Schedule", })
    }
    this.callLog();
  }
  callStateVariable = () => {
    let repeatValue = "";
    if (this.state.value === 'Daily') {
      repeatValue = this.state.dailyValue + " days"
    } else if (this.state.value === 'Weekly') {
      repeatValue = this.state.WeeklyValue + " weeks"
    } else if (this.state.value === 'Monthly') {
      repeatValue = this.state.MonthlyValue + " months"
    }
    const body = {
      "milestone_management_id": this.state.milestonesID,
      "title": this.state.title,
      "description": this.state.editorState,
      "start_date": JSON.stringify(this.state.selectedDate),
      "start_time": JSON.stringify(this.state.selectedTime),
      "address": this.state.address1 + " " + this.state.address2 + " " + this.state.address3,
      "recurring_meeting": (this.state.value).toLowerCase(),
      "occurs_on": this.state.selectedDays,
      "end_date": JSON.stringify(this.state.endDate),
      "on_day": this.state.on_day,
      "on_the": this.state.onthevalue,
      "month": this.state.yearlyDate,
      "day_month": this.state.yearlyDayFormat,
      "day_on_the": this.state.dayonvalue,
      "duration_hour": this.state.setDuration,
      "minute": this.state.setMinute,
      "patient_ids": this.state.patientIdsArray,
      "repeat_every": repeatValue,
      "time_zone": this.state.selectedTimeZone,
      "custom_period":this.state.customValue
    }
    return body
  }
  editScheduleApi = () => {
    let token = localStorage.getItem("token")
    if (this.state.addPatientList && this.state.addPatientList.length > 0) {
      let arr = [];
      arr = this.state.addPatientList.map((item: { id: any; }) => item.id);
      this.setState({ patientIdsArray: arr })

    }
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": token
    };
    const bodyis = this.callStateVariable()


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editScheduleApiEndPoint + this.state.scheduleID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiPutMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyis)
    );
    this.editScheduleApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  SubmitAddSchedule = () => {
    let token = localStorage.getItem("token")
    if (this.state.addPatientList && this.state.addPatientList.length > 0) {
      let arr = [];
      arr = this.state.addPatientList.map((item: { id: any; }) => item.id);
      this.setState({ patientIdsArray: arr })

    }

    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      "token": token
    };
    const bodyis = this.callStateVariable()

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addScheduleEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyis)
    );
    this.addScheduleApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllAppointments = (milestone_id: any) => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllScheduleApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAppointment + milestone_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  showDailogDelete = (contentid: any) => {
    this.setState({ deleteFlag: true, scheduleID: contentid })
  }
  handleDeleteItemClick = () => {
    this.setState({ deleteFlag: false })
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getScheduleDeleteApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getScheduleDeleteApiEndPoint + this.state.scheduleID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAllAppointmentsAPiMethod
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEditItemClick = (content: any) => {
    const timeString = content.attributes.start_time; // Example time string
    const [time, period] = timeString.split(' '); // Split the time string into time and period (AM/PM)
    let [hours, minutes] = time.split(':').map(Number); // Split the time into hours and minutes
    if (hours === 12) {
      hours = period === 'AM' ? 0 : 12;
    } else if (period === 'PM') {
      hours += 12;
    }
    const selectedTime = new Date();
    selectedTime.setHours(hours);
    selectedTime.setMinutes(minutes);

    if (content.attributes.recurring_meeting) {
      this.setState({
        checked: true,
        value: content.attributes.recurring_meeting
      }, () => {
        if ((this.state.value.toLowerCase()) === 'Daily'.toLowerCase()) {
          this.setState({ dailyValue: content.attributes.repeat_every.replace(/\D/g, '') })

        } else if (this.state.value.toLowerCase() === 'Weekly'.toLowerCase()) {
          this.setState({ WeeklyValue: content.attributes.repeat_every.replace(/\D/g, '') })
        } else if (this.state.value.toLowerCase() === 'Monthly'.toLowerCase()) {
          this.setState({ MonthlyValue: content.attributes.repeat_every.replace(/\D/g, '') })
        }
      })
    }


    const foundItem = this.state.milestones.find((item: any) => item.id === content.attributes.milestone_management_id);
    if (foundItem) {
      this.setState({ selectedOption: foundItem.name, milestonesID: foundItem.id })
    }

    this.setState({
      open: true,
      editFlag: true,
      scheduleID: content.id,
      add_Schedule_Title: "Edit Schedule",
      selectedTimeZone: content.attributes.time_zone,
      title: content.attributes.title,
      editorState: content.attributes.description,
      addPatientList: content.attributes.patient_ids,
      selectedDate: content.attributes.start_date,
      selectedTime: selectedTime,
      address1: content.attributes.address,
      setDuration: content.attributes.duration_hour,
      setMinute: content.attributes.minute,
      endDate: content.attributes.end_date,
      selectedDays: content.attributes.occurs_on,
      on_day: content.attributes.on_day,
      onthevalue: content.attributes.on_the,
      yearlyDate: content.attributes.month,
      yearlyDayFormat: content.attributes.day_month,
      dayonvalue: content.attributes.day_on_the,

    })
  }

  // Customizable Area End
}
